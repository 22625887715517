#CardDeck {
  flex-wrap: wrap;
  justify-content: center;

  border: #999;
}

.CardsRow > #CardDeck > #newscard {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  -webkit-box-shadow: -8px 8px 6px -6px #999;
  -moz-box-shadow: -8px 8px 6px -6px #999;
  box-shadow: -8px 8px 6px -6px #999;
  width: 20rem;
  text-align: justify;
  color: black;
}

.CardImages {
  width: 400px;
  height: 300px;
}
