.all-products-main-container{
    background-image: url("https://res.cloudinary.com/da8slr0ic/image/upload/v1687631861/foodsfromturkey/haxgohmrlti44ylmzcw2.jpg");
    background-size: cover;
    position: relative;
    z-index: 0;
    padding-inline: 2rem !important;
}

.all-products-main-container::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--fixed-text-dark);
    opacity: 0.9;
    z-index: 1;
}

.all-products-main-container .prod-table-wrapper{
    position: relative;
    z-index: 2;
}

.all-products-main-container .prod-table-wrapper h1{
    font-size: 24pt;
    font-weight: bold;
    color: var(--fixed-secondary);
    
}

.all-products-main-container .prod-table-wrapper p{
    margin-top: 1rem;
    font-size: 14pt;
    color: var(--fixed-text-white-sec);
    
}

