:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}


@media screen and (min-width: 991px) {

  #Title{
    font-size: 24pt !important;
  }

  .subtitle {
    font-size: 18pt !important;
  }
}
@media screen and (max-width: 990px) {

  #Title{
    font-size: 20pt !important;
  }

  .subtitle {
    font-size: 14pt !important;
  }
}


@media screen and (min-width: 1140px) {
  .main-body {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .main-body .author-col {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: auto;
  }

  .main-body .related-news-col {
    padding-right: 20px;
    padding-left: 40px;
    border-left: solid 2px;
    border-color: var(--fixed-color-dark);
  }
}

#Body {
  justify-content: center;
  flex-direction: column;
  height: fit-content;

  text-align: justify;
  padding-left: 5%;
  padding-right: 5%;
}
#top-image-row {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}

#Body #ReferencesId {
  /*margin-left: 20rem;*/
  margin-top: 5%;
}

#Title {
  align-items: flex-start;
  margin-bottom: 50px;
  font-weight: bold;
}

#tags {
  justify-content: center;

  align-items: center;
  margin-right: 10%;
  margin-left: 10%;
}

#Author {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 30%;
  margin-right: 10%;
  margin-left: 10%;
  background-color: var(--fixed-secondary);
}

#middle-row {
  margin-top: 50px;
}
