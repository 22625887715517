:root {
    --fixed-primary: #6d8c54;
    --fixed-secondary: #f1cf69;
    --fixed-color-dark: #334b35;
    --border-width: 3px;
    --animation-speed: 0.5s;
  }
  
  .header-section-wrap {
    color: var(--fixed-primary);
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  
  .header-section-wrap i{
      font-size: 3rem;
  }
  
  .header-section-title{
    font-size: 4rem;
    color: #31512a;
    margin-bottom: 20px;
    line-height: 60px;
  }
  .header-section-subtitle{
    font-size: 1.5rem;
    color: #31512a;
    margin-bottom: 20px;
    padding-inline: 3rem;
    line-height: 30px;
  }


  @media (max-width: 450px) {
    .header-section-title {
      font-size: 30px;
      line-height: 40px;
    }
    .header-section-subtitle{
      font-size: 1.5rem;
      color: #31512a;
      margin-bottom: 20px;
      padding-inline: 1rem;
      line-height: 30px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-section-title {
      font-size: 42px;
      line-height: 50px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-section-title {
      font-size: 40px;
      line-height: 50px;
    }
    .header-section-subtitle{
      font-size: 1.5rem;
      color: #31512a;
      margin-bottom: 20px;
      padding-inline: 1rem;
      line-height: 30px;
    }
  }