@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
/* This pen */
 .sup_body {
	 font-family: "Baloo 2", cursive;
	 font-size: 16px;
	 color: #fff;
	 text-rendering: optimizeLegibility;
	 font-weight: initial;
}
 .dark {
	 background: #110f16;
}
 .light {
	 background: #f3f5f7;
}

.subs-button, .subs-button:hover {
	font-size: 12pt;
	font-weight: 600;
	text-decoration: none;
	transition: color 0.3s ease-in-out;

}

.subs-button {
	color: rgb(226, 226, 226);

}
.subs-button:hover {
	color:rgb(255, 255, 255);

}
a, a:hover {
	 text-decoration: none;
	 transition: color 0.3s ease-in-out;
}
 #pageHeaderTitle {
	 margin: 2rem 0;
	 text-transform: uppercase;
	 text-align: center;
	 font-size: 2.5rem;
}
/* Cards */
 .postcard {
	 flex-wrap: wrap;
	 display: flex;
	 box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
	 border-radius: 10px;
	 margin: 0 0 2rem 0;
	 overflow: hidden;
	 position: relative;
	 color: #fff;
}
 .postcard.dark {
	 background-color: #18151f;
}
 .postcard.light {
	 background-color: #e1e5ea;
}
 .postcard .t-dark {
	 color: #18151f;
}

 .postcard h1, .postcard .h1 {
	 margin-bottom: 0.5rem;
	 font-weight: 500;
	 line-height: 1.2;
}
 .postcard .small {
	 font-size: 80%;
}
 .postcard .postcard__title {
	 font-size: 1.75rem;
}
 .postcard .postcard__img {
	 max-height: 180px;
	 width: 100%;
	 object-fit: cover;
	 position: relative;
}
 .postcard .postcard__img_link {
	 display: contents;
}
 .postcard .postcard__bar {
	 width: 50px;
	 height: 10px;
	 margin: 10px 0;
	 border-radius: 5px;
	 background-color: #424242;
	 transition: width 0.2s ease;
}
 .postcard .postcard__text {
	 padding: 1.5rem;
	 position: relative;
	 display: flex;
	 flex-direction: column;
}
 .postcard .postcard__preview-txt {
	 overflow: hidden;
	 text-overflow: ellipsis;
	 text-align: justify;
	 height: 100%;
}
 .postcard .postcard__tagbox {
	 display: flex;
	 flex-flow: row wrap;
	 font-size: 14px;
	 margin: 20px 0 0 0;
	 padding: 0;
	 justify-content: center;
}
 .postcard .postcard__tagbox .tag__item {
	height: 40px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 text-align: center;
	 background: rgba(83, 83, 83, 0.4);
	 border-radius: 3px;
	 padding: 2.5px 10px;
	 margin: 0 5px 5px 0;
	 cursor: default;
	 user-select: none;
	 transition: background-color 0.3s;
}
 .postcard .postcard__tagbox .tag__item:hover {
	 background: rgba(83, 83, 83, 0.8);
}
 .postcard:before {
	 content: "";
	 position: absolute;
	 top: 0;
	 right: 0;
	 bottom: 0;
	 left: 0;
	 background-image: linear-gradient(-70deg, #424242, transparent 50%);
	 opacity: 1;
	 border-radius: 10px;
}
 .postcard:hover .postcard__bar {
	 width: 100px;
}
 @media screen and (min-width: 769px) {
	 .postcard {
		 flex-wrap: inherit;
	}
	 .postcard .postcard__title {
		 font-size: 2rem;
	}
	 .postcard .postcard__tagbox {
		 justify-content: start;
	}
	 .postcard .postcard__img {
		 max-width: 300px;
		 max-height: 100%;
		 transition: transform 0.3s ease;
	}
	 .postcard .postcard__text {
		 padding: 3rem;
		 width: 100%;
	}
	 .postcard .media.postcard__text:before {
		 content: "";
		 position: absolute;
		 display: block;
		 background: #18151f;
		 top: -20%;
		 height: 130%;
		 width: 55px;
	}
	 .postcard:hover .postcard__img {
		 transform: scale(1.1);
	}
	 .postcard:nth-child(2n+1) {
		 flex-direction: row;
	}
	 .postcard:nth-child(2n+0) {
		 flex-direction: row-reverse;
	}
	 .postcard:nth-child(2n+1) .postcard__text::before {
		 left: -12px !important;
		 transform: rotate(4deg);
	}
	 .postcard:nth-child(2n+0) .postcard__text::before {
		 right: -12px !important;
		 transform: rotate(-4deg);
	}
}
 @media screen and (min-width: 1024px) {
	 .postcard__text {
		 padding: 2rem 3.5rem;
	}
	 .postcard__text:before {
		 content: "";
		 position: absolute;
		 display: block;
		 top: -20%;
		 height: 130%;
		 width: 55px;
	}
	 .postcard.dark .postcard__text:before {
		 background: #18151f;
	}
	 .postcard.light .postcard__text:before {
		 background: #e1e5ea;
	}
}
/* COLORS */
 .postcard .postcard__tagbox .green.play:hover {
	 background: #79dd09;
	 color: black;
}
 .green .postcard__title:hover {
	 color: #79dd09;
}
 .green .postcard__bar {
	 background-color: #79dd09;
}
 .green::before {
	 background-image: linear-gradient(-30deg, rgba(121, 221, 9, 0.1), transparent 50%);
}
 .green:nth-child(2n)::before {
	 background-image: linear-gradient(30deg, rgba(121, 221, 9, 0.1), transparent 50%);
}

.postcard .postcard__tagbox .blue.play {
	background: #1d90d8;
}

 .postcard .postcard__tagbox .blue.play:hover {
	 background: #0076bd;
}
 .blue .postcard__title:hover {
	 color: #0076bd;
}
 .blue .postcard__bar {
	 background-color: #0076bd;
}
 .blue::before {
	 background-image: linear-gradient(-30deg, rgba(0, 118, 189, 0.1), transparent 50%);
}
 .blue:nth-child(2n)::before {
	 background-image: linear-gradient(30deg, rgba(0, 118, 189, 0.1), transparent 50%);
}
.postcard .postcard__tagbox .red.play {
	background: #cc281f;
}
 .postcard .postcard__tagbox .red.play:hover {
	 background: #bd150b;
}
 .red .postcard__title:hover {
	 color: #bd150b;
}
 .red .postcard__bar {
	 background-color: #bd150b;
}
 .red::before {
	 background-image: linear-gradient(-30deg, rgba(189, 21, 11, 0.1), transparent 50%);
}
 .red:nth-child(2n)::before {
	 background-image: linear-gradient(30deg, rgba(189, 21, 11, 0.1), transparent 50%);
}
 .postcard .postcard__tagbox .yellow.play:hover {
	 background: #bdbb49;
	 color: black;
}
 .yellow .postcard__title:hover {
	 color: #bdbb49;
}
 .yellow .postcard__bar {
	 background-color: #bdbb49;
}
 .yellow::before {
	 background-image: linear-gradient(-30deg, rgba(189, 187, 73, 0.1), transparent 50%);
}
 .yellow:nth-child(2n)::before {
	 background-image: linear-gradient(30deg, rgba(189, 187, 73, 0.1), transparent 50%);
}
 @media screen and (min-width: 769px) {
	 .green::before {
		 background-image: linear-gradient(-80deg, rgba(121, 221, 9, 0.1), transparent 50%);
	}
	 .green:nth-child(2n)::before {
		 background-image: linear-gradient(80deg, rgba(121, 221, 9, 0.1), transparent 50%);
	}
	 .blue::before {
		 background-image: linear-gradient(-80deg, rgba(0, 118, 189, 0.1), transparent 50%);
	}
	 .blue:nth-child(2n)::before {
		 background-image: linear-gradient(80deg, rgba(0, 118, 189, 0.1), transparent 50%);
	}
	 .red::before {
		 background-image: linear-gradient(-80deg, rgba(189, 21, 11, 0.1), transparent 50%);
	}
	 .red:nth-child(2n)::before {
		 background-image: linear-gradient(80deg, rgba(189, 21, 11, 0.1), transparent 50%);
	}
	 .yellow::before {
		 background-image: linear-gradient(-80deg, rgba(189, 187, 73, 0.1), transparent 50%);
	}
	 .yellow:nth-child(2n)::before {
		 background-image: linear-gradient(80deg, rgba(189, 187, 73, 0.1), transparent 50%);
	}
}



 