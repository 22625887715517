:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}

.inside-row {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

}

.main-row {
  display: block !important;
  width: auto;
  margin-bottom: 50px;

}

.jumbo-main {
  padding-top: 4rem;
  padding-right: 5rem;
  padding-left: 5rem;
  position: relative;
  width: 100%;
  height: 850px;
  overflow-x: hidden;
}

.jumbo-main .inside-row .text-box {


  word-break: normal;
  text-align: justify ;
  
}

.jumbo-main .inside-row .text-box .text-box-wrapper {
  position: absolute;
  top: 5rem;
  left: 0;
  width: 120%;
  height: 700px;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-right: 100px;
  padding-left: 4rem;
  background-color: var(--fixed-primary);
  border-radius: 30px;

}



.jumbo-main .inside-row h1 {
  font-size: 3rem;
  font-weight: 750;
  margin-bottom: 1rem;
 
}

.jumbo-main .inside-row .text-box p {
  line-height: 30px;
  font-size: 10pt;
  font-weight: 400;
  margin-top: 2rem;
  font-weight: 600;
  color: rgb(14, 14, 14);
  
}

.jumbo-main .img-box {
  align-content: center;
  position: relative;
  max-height: 500px;
  width: 100%;
  height: 500px;
}

.jumbo-main .inside-row .background-div{
  position: absolute;
  width: 80%;
  height: 700px;
  z-index: -1;
  top: 2rem;
  right: -100px;
  background-color: var(--fixed-secondary);
  border-radius: 30px;
}

.jumbo-main .img-box img {
  object-fit: cover;
  object-position: top left;
  position: absolute;
  top: 0;
  left: 100px;
  width: 80%;
  height: 600px;
  border-radius: 30px;
}



@media only screen and (max-width : 1200px) {
  .jumbo-main {
    padding-top: 4rem;
    padding-right: 1rem;
    padding-left: 1rem;
    position: relative;
    width: 100%;
    height: auto;
    overflow-x: hidden;
  }
  .jumbo-main .inside-row .text-box {
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-right: 2rem;
    padding-left: 2rem;
    word-break: normal;
    text-align: left;
  }
  .jumbo-main .inside-row .text-box .text-box-wrapper {
 
    top: 5rem;
    left: 0;
    width: 120%;
    height: 650px;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-right: 3rem;
    padding-left: 4rem;
    background-color: var(--fixed-primary);
   border-radius: 30px;
  }  
  .jumbo-main .img-box{
    max-height: 500px;
  }
  .jumbo-main .img-box img {
    object-fit: cover;
    object-position: top left;
    width: 100%;
    left: 0;
    height: 500px;
    aspect-ratio: 1 1;
    border-radius: 30px;
  }
}




/* .jumbo-main .img-box img::after {
  content: "";
  position: absolute;
  right: 0;
  z-index: 150;
  top: 0;
  height: 10px;
  width: 50%; 
  background: var(--fixed-color-dark);
}

.jumbo-main .img-box::before {
  content: "";
  position: absolute;
  left: 10;
  z-index: 100;
  top: 0;
  height: 50%;
  width: 10px;
  background: var(--fixed-primary);
}
.jumbo-main .img-box::after {
  content: "";
  position: absolute;
  right: 0;
  z-index: 100;
  bottom: 0;
  height: 10px;
  width: 50%; 
  background: var(--fixed-primary);
} */
