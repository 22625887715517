:root{
    --disabled-color: rgb(143, 143, 143);
    --disabled-back: rgb(206, 206, 206);
}


.panel-wrapper{
    padding: 20px 15px;
}

.panel-wrapper.disabled{
    pointer-events: none;
    color: grey;
}

.panel{
    position: relative;
    padding: 30px 15px;
    width: 100%;
    border-radius: 10px;
    flex: 1;   
    background-color: rgb(248, 248, 248);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.panel-wrapper.disabled .panel{
    background-color: rgb(206, 206, 206);
}
/* .panel::before{
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 2px;
    background-color: var(--fixed-primary-dark);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    opacity: 0.3;
} */

.panel-title-row {
    width: 100%;
}

.panel-wrapper .panel-title-col, .panel-wrapper .panel-title-col>*{
    text-align: left;
    color: var(--fixed-primary);
    align-items: center;
    display: flex;
}

.panel-wrapper.disabled .panel-title-col, .panel-wrapper.disabled .panel-title-col>*{
    text-align: left;
    color: var(--disabled-color);
}

.icon {
    scale: 1.5;
    cursor: pointer;
    align-items: center;
}

.icon:hover {
    cursor: pointer;
    color: var(--fixed-secondary-dark);
}

.angle-up{
    transition: 0.6s;
    display: flex;
    margin-left: auto;
    transform: rotate(-180deg);
}
.angle-down{
    display: flex;
    margin-left: auto;
    transition: 0.6s;
}
