.table-wrapper{
 
  font-family: 'Poppins';
    /* padding-right:1rem;
    padding-left:1rem;
    padding-top:1rem;
    padding-bottom:1rem; */
    margin-top: 2rem;
    border-radius: 10px;
    /* background-color: var(--fixed-color-dark); */
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}


.table-wrapper:has( .toolbar-main-container) {
  border-radius: 0;
}
.table-wrapper > .fZxLXy{
  border-radius: none !important;
}

.row .jvsFmi{
  border-radius: 0;
}

.row  .rdt_Table {

    height: 600px;

    padding-right: 0;
}

.row .rdt_Table .rdt_TableBody{
  overflow-y: scroll;
}
.rdt_Table .rdt_TableHead{

   z-index: 0 !important;
}


/* width */
::-webkit-scrollbar {
    width: 0;
  }

  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: var(--fixed-color-dark);
    width: 0;
   
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--fixed-secondary);
  }