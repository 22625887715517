#Popular {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

#Popular > #newsrow > #Title {
  padding: 1rem;
}
#Popular > #Cartsrow {
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}
#Carts > .card-img-top {
  height: 17rem;
  width: 100%;
}
#Carts > .card-body {
  justify-content: center;
  text-align: center;
}

#Carts {
  margin-left: 1.9rem;
  margin-right: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  -webkit-box-shadow: -8px 8px 6px -6px #999;
  -moz-box-shadow: -8px 8px 6px -6px #999;
  box-shadow: -8px 8px 6px -6px #999;
  width: 20rem;
}
