.about-us-main-container{
    height: 100%;
    background-color: var(--fixed-secondary);
    
}

/* Layout*/
.about-us-main-container .row {
    width: 100%;
    padding-inline: 4rem ;
    justify-content: center;
    padding-block: 5rem;
}



.about-us-main-container .aboutus-cards-row {
    background-color: var(--fixed-primary);
}


.about-us-main-container .row-r{
    display: flex;
    flex-direction: row-reverse;
} 

.about-us-main-container .row-l{
    display: flex;
    flex-direction: row;
} 


.about-us-main-container .row .num-card{
    color: var(--fixed-primary);
    font-weight: bold;
}


/* Image part*/

.about-us-main-container .row .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 500px;
    min-width: 400px;
    text-align: center;
}

.about-us-main-container .row .img-wrapper img{
    width: 90%;
    height: 90%;
    object-fit: cover;
    position: relative;
    z-index: 3;
}

.about-us-main-container .row-r .img-wrapper::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 90%;
    height: 90%;
    background-color: var(--fixed-primary);
   
    z-index: 0;
}

.about-us-main-container .row-l .img-wrapper::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    width: 90%;
    height: 90%;
    background-color: var(--fixed-primary);

    z-index: 0;
}

/* Texts*/
.about-us-main-container .row h1{
    font-weight: bold;
    color: var(--fixed-text-dark);
    font-size: 32pt;
    margin-block: 1.5rem;
}
.about-us-main-container .row h2{
    font-weight: bold;
    color: var(--fixed-text-dark);
    font-size: 32pt;
    margin-block: 1.5rem;
}
.about-us-main-container .row h3{
    font-weight: 500;
    color: var(--fixed-text-dark);
    font-size: 24pt;
    margin-block: 1rem;
}
.about-us-main-container .row p{
    font-weight: 500;
    color: var(--fixed-text-dark);
    font-size: 14pt;
    text-align: justify;
    margin-block: 3rem;
}


/*Media*/


@media only screen and (max-width: 992px) {
    .about-us-main-container .row {
        padding-inline: 0.5rem ;
    }

    .about-us-main-container .row-r{
        display: flex;
        flex-direction: column-reverse;
    } 
    
    .about-us-main-container .row-l{
        display: flex;
        flex-direction: column;
    } 

    .about-us-main-container .row .img-wrapper::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background-color: var(--fixed-primary);
        transform: translate(-50%,-50%);
        z-index: 0;
    }
}
