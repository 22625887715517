.rdt_Table {

    height: 600px !important;
}
.rdt_TableBody {

    overflow-y: scroll;
    cursor: pointer;
}

.category-select{
    width: 100%;
    color: var(--fixed-text-dark);
}