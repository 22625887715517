#main-container > .row > {
  display: flex;
}
#main-container > .row > #leftbar {
  background-color: white;
  height: 50rem;
  flex-grow: 0.1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border-right: brown solid;
  padding-right: 0rem;
  width: fit-content;
}

#main-container > .row > #leftbar>.btn-group{
 width: 10rem;
}

.news-form {
  text-align: center;
}


#admin-submit-button {
  background: #5b8c51;
  border: border solid 1px ;
  border-color: var(--fixed-primary);
  color: #ffffff;
  cursor: pointer;
  border-radius: 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 1;
  margin-bottom: 0;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  transition: 0.3s;
  vertical-align: middle;
  white-space: nowrap;
  padding: 10px 20px;
  letter-spacing: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#admin-submit-button:hover {
  border: 1px solid var(--fixed-color-dark);
  background-color: white !important;
  color: var(--fixed-color-dark) !important;
  
}