:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}
#hero-main {
  position: relative;
}

#hero {
  width: 100%;
  height: 100vh;
  background: url("../../assets/istockphoto-506164764-170667a.webp") top center;
  background-size: cover;
  position: relative;
}
#hero:before {
  content: "";
  background: rgba(51, 75, 53, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .main-hero-row{
  width: 100%;
  height: 100%;
}
#hero .col{
  display: flex;
}

#hero .col span{
  margin:auto;
  text-align: center;
}

#hero span p{
  color: rgb(227, 227, 227);
  font-size: 13pt;
}

#hero .search-col{
  background-color: rgba(59, 59, 59, 0.3);
}
#hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
  font-family: "Poppins", sans-serif;
}
#hero h1 span {
  color: var(--fixed-secondary);
}
#hero h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 3rem 0 0 0;
  font-size: 24px;
}
#hero .icon-box {

  transition: ease-in-out 0.3s;
  
  height: 100%;
  text-align: center;
  text-decoration: none;
  justify-content: center;
}
#hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: var(--fixed-secondary);
}
#hero .icon-box h3 {
  padding: 30px 20px;
  width: 100%;
  height: 100%;
  font-weight: 700;
  margin: 10px 0 0 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
  color: white;
  text-decoration: none;
  transition: 0.6s;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
#hero .icon-box:hover h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
  color: var(--fixed-secondary);
  text-decoration: none;
  transition: 0.6s;
  border-color: var(--fixed-secondary);
}

#hero .header-col{
  flex-direction: column;
}

#hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}
#hero .icon-box h3 a:hover {
  color: var(--fixed-secondary);
}
#hero .icon-box:hover {
  border-color: var(--fixed-secondary);
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
  #hero .header-col{
    padding-inline: 3rem ;
  }
  
}
@media (max-width: 768px) {
  #hero {
    height: auto;
    padding-top:5rem;
    padding-bottom:5rem;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 20px;
    line-height: 24px;
  }
  #hero .search-col{
    display: none;
  }
}
