.num-disp-wrapper{
    margin: 2rem;
    box-shadow: 2px;
    text-align: center;
    justify-content: center;
    display: flex;
}

.num-disp-wrapper .num-disp-card{
    width: 200px;
    text-align: center;
    color: var(--fixed-primary);
    background-color: var(--fixed-secondary);;
    font-weight: bold;
    transition: 0.6s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 1rem;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    
}
.num-disp-wrapper .num-disp-card .label{
    color: inherit;
    text-align: center;
    font-weight: bold;
    font-size: 18pt;
    margin: 0px;

}
.num-disp-wrapper .num-disp-card .num{
    font-weight: bold;
    text-align: center;
    font-size: 14pt;
    color: inherit;
    margin: 0px;
}
