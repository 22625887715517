


.fairs-carousel  .fairs-icon-box {
  height: 525px;
}
.control-dots .dot {
    background: #a3a3a3 !important;
    margin: -8px 8px !important;
  }
  .fairs-carousel .control-dots  .dot.selected {
    background: var(--fixed-primary) !important;
    
  }
  .fairs-carousel.carousel-status {
    color: var(--fixed-text-dark) !important;
    text-shadow: none !important;
    font-weight: 600
    ;
  }

  .fairs-carousel.control-arrow, .carousel.carousel-slider .control-arrow{
    padding: 10px !important;
  }
  .fairs-carousel.control-arrow, .carousel.carousel-slider .control-arrow::before{
    scale: 1.2;
  }