:root {
  --fixed-color-dark: #334b35;
  --fixed-secondary: #f1cf69;
}

.main-services .icon-box {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}
.main-services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: var(--fixed-color-dark);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.main-services .icon-box .image {
  margin: 0 auto;
  width: 256px;
  height: 256px;
  background: var(--fixed-color-dark);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}
.main-services .icon-box .icon i {
  color: #151515;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}
.main-services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #151515;
  transition: ease-in-out 0.3s;
}

.main-services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.main-services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  background-color: var(--fixed-secondary);
}

.main-services .icon-box:hover h4 {
  color: #fff;
}
