@import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap");

:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}
.productHeader {
  padding: 10px;
}
#productrow {
  display: flex;
  flex-wrap: wrap;
}

.flex-col{
  margin-bottom: 3rem;
  width: 100%;
}

#product-mid-row {
  justify-content: flex-start;
  align-items: flex-start;
}

#RequestCol > .row {
  justify-content: center;
}
#tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  padding-top: 1rem;
  width: 100%;
}
#tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  border-style: none;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 15px 10px 10px 15px;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

#tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

#tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

#tag:hover {
  transition: ease-in-out 0.5s;
  background-color: var(--fixed-secondary);
  color: white;
}

#tag:hover::after {
  transition: ease-in-out 0.5s;
  border-left-color: var(--fixed-secondary);
}

@media screen and (min-width: 1440px) {
  #ProductTopRow {
    margin-right: 10%;
    margin-left: 10%;
    padding: 1rem;
  }
}
@media screen and (max-width: 992px) {
  #ProductTopRow {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.CardsRow {
  padding-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  text-align: left;
}

#description-span {
  text-align: left;
}
#product-mid-row #titlerow {
  margin-top: 2rem;
  margin-left: 3vw;
  margin-right: 3vw;
}

#product-mid-row .description-row {
  margin-left: 3vw;
  margin-right: 3vw;
}

.tp-section-wrap span i {
  font-size: 30px;
  color: #5b8c51;
  margin-bottom: 20px;
}
.tp-section-wrap p {
  font-size: 16px;
  line-height: 26px;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.tp-section-wrap-h3 p {
  font-size: 18px;
  line-height: 30px;
}

.project-details-image {
  position: relative;
}

.project-d-info {
  position: absolute;
  right: -65%;
  top: 40px;
  max-width: 370px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  padding: 40px;
  min-height: 355px;
  background: #ffffff;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-d-info {
    right: -30%;
    top: 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px),
  (max-width: 575px) {
  .project-d-info {
    display: none;
  }
}

.project-d-info-title {
  /* font-size: 24px; */
  display: flex;
  flex-direction: row;
  line-height: 20px;
  position: relative;
  color: #31512a;
  margin-top: 0px;
  font-weight: 500;
  transition: all 0.3s ease-out 0s;
}
.project-d-info-title h5{
  margin-bottom: 0 !important;
}
.project-info-lists {
  margin-top: 40px;
}
.project-d-info-title::before {
  position: absolute;
  content: "";
  background: #e3e3e3;
  bottom: -15px;
  width: 100%;
  height: 1px;
}

.d-info-item {
  height: 80px;
  width: 50%;
  float: left;
  margin-bottom: 20px;
}
.d-info-item h6 {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0;
  color: #31512a;
}
.d-info-item p {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0;
}

.project-d-bt {
  margin-top: 30 px;
}

.project-d-btn .fa-share {
  font-size: 18px;
  color: #777777;
  height: 50px;
  width: 50px;
  border: 2px solid #e7e7e7;
  line-height: 40px;
  text-align: center;
  margin-right: 30px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  margin-left: 20px;
  margin-top: 30 px;
}

.p-d-btn {
  padding: 15px 30px;
}
.p-d-btn i {
  margin-left: 10px;
  font-size: 20px;
}

.tp-btn-h1 {
  width: 100%;
  background: #5b8c51;
  border: medium none;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 1;
  margin-bottom: 0;
  padding: 20px 40px;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  transition: 0.3s;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  letter-spacing: 0;
}
.tp-btn-h1:hover {
  background: #ffffff;
  color: #5b8c51;
  border-color: #5b8c51;
}

.project-d-descriptiopn {
  position: relative;
  margin-top: 6rem;
  margin-bottom: 8rem;
}
.project-d-descriptiopn .description-row{
  padding-left: 10%;
  padding-right: 10%;
}
@media only screen and (max-width:991px){

  .project-d-descriptiopn .description-row {
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}


.project-d-descriptiopn::before{
  content: "";
  width: 50%;
  position: absolute;
  top: -3rem;
  height: 10px;
  background: linear-gradient(90deg, var(--fixed-primary),white);
}
.project-d-descriptiopn::after{
  content: "";
  width: 50%;
  position: absolute;
  bottom: -3rem;
  right: 0;
  height: 10px;
  background: linear-gradient(-90deg, var(--fixed-primary),white);
}

.project-d-descriptiopn .description-title {
  color: var(--fixed-color-dark);
}

.project-d-descriptiopn h1 {
  font-size: 1rem;
}
.project-d-descriptiopn h2, .project-d-descriptiopn h {
  color: var(--fixed-secondary-dark);
}

.display-request-col h2{
  font-size: xxx-large;
}

.project-d-descriptiopn p {
  font-size: 18px;
  line-height: 30px;
  white-space: break-spaces;
  text-align: justify;
}

.p-quote {
  position: absolute;
  font-size: 60px;
  line-height: 100px;
  height: 100px;
  width: 100px;
  background: #5b8c51;
  color: #ffffff;
  display: block;
  font-family: "Prata", serif;
  text-align: center;
  top: -10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .p-quote {
    top: 8px;
  }
}

.floating-section-rows .display-request-col{
  position: relative;
  z-index: 2;
  margin-top: 10%;
  margin-left: 4rem;
  color: var(--fixed-color-dark);
}
.floating-section-rows .display-request-col p{
  text-align: justify;
}

.display-request-img-col .display-request-img-wrapper{
  position: relative;

}
.display-request-img-col .display-request-img-wrapper>img{
  position: absolute;
  right: 0;
  width: 150%;
  max-height: 1000px;

}
.request-form-img-col .request-form-img-wrapper{
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;

}
.request-form-img-col .request-form-img-wrapper>img{
  object-fit: cover;
  rotate: 30deg;
  width: 100%;
 

}


@media only screen and (max-width:991px){
  .floating-section-rows .display-request-col{
    margin-left: 0;
  }
  .display-request-img-col .display-request-img-wrapper{
    display: none;
    flex: 0;
  
  }

  .request-form-img-col .request-form-img-wrapper{
    display: none;
    flex: 0;
  }

}