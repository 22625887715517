#modal-main-container.container{
padding: 1rem !important;
}

.col-xl-12 .toolbar-button {
  font-size: 12pt;
  border-color: white;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border-radius: 10px;
}

.col-xl-12 .toolbar-button:hover {
  border-color: var(--fixed-primary);
  background-color: rgba(255, 255, 255, 1);
  color: var(--fixed-primary);
  border-radius: 15px;
}

.col-12 .modal-button {
  border-radius: 10px;
}

.col-12 .modal-delete-btn {
  border-color: white;
  background-color: rgb(179, 37, 37);
  color: white;
}

.col-12 .modal-delete-btn:hover {
  border-color: rgb(179, 37, 37);
  background-color: white;
  color: rgb(179, 37, 37);
}

.col-12 .modal-answer-btn {
  border-color: white;
  background-color: var(--fixed-primary);
  color: white;
}

.col-12 .modal-answer-btn:hover {
  border-color: var(--fixed-primary);
  background-color: white;
  color: var(--fixed-primary);
}
