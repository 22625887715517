.toolbar-main-container{
    background-color: var(--fixed-primary);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-top: 10px !important;
 
}

.toolbar-main-container h4, .toolbar-main-container label{
    color: var(--fixed-text-white);
}

.toolbar-main-container .toolbar-btn{
    padding: 3px 3px;
    width: 100px;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    outline: none;

    font-size: small !important;
}
.toolbar-main-container  .toolbar-btn:focus{
    outline: none;
    box-shadow: none;
}
.toolbar-main-container  .toolbar-btn:active{
    outline: none;
    box-shadow: none;
}
#toolbar-add-btn{
    background-color: green;
    border-color: green;
}
#toolbar-add-btn:hover{
    background-color: white;
    color: green;

}
#toolbar-delete-btn{
    background-color: rgb(197, 52, 52);
    border-color: rgb(197, 52, 52);
}
#toolbar-delete-btn:hover{
    background-color: white;
    color: rgb(197, 52, 52);
    border-color: rgb(197, 52, 52);
}