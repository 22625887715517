@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap");

#Popular {
  margin-top: 8rem;
  margin-bottom: 2rem;
}

#Popular > #newsrow > #Title {
  padding: 1rem;
}

/* #Title {
  font-size: 2.5rem;
  font-weight: 500;
  font-family: "Libre Franklin", sans-serif;
} */

#Carts {
  margin-left: 1.9rem;
  margin-right: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  -webkit-box-shadow: -8px 8px 6px -6px #999;
  -moz-box-shadow: -8px 8px 6px -6px #999;
  box-shadow: -8px 8px 6px -6px #999;
  width: 20rem;
}
@media (max-width: 680px) {
  div#Carts {
    margin: 2rem;
  }
}

#Carts > .card-img-top {
  height: 17rem;
  width: 100%;
}

#cards-row {
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

#cards-row-product {
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: flex-start;
}

#HomeContainer {
  padding-left: 0rem;
  padding-right: 0rem;
  background-color: #fffffd;
}
#HomeContainer > #SliderRow {
  padding-left: 0rem;
  padding-right: 0rem;
  background-color: #fffffd;
  width: 100%;
  margin-right: 0rem;
  margin-left: 0rem;
  height: 100%;
}

#HomeContainer > .InfoDeck {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
  padding-right: 0rem;
  background-color: #30421a;
  width: 100%;
  height: 10rem;
  margin-top: 2rem;
  margin-right: 0rem;
  margin-left: 0rem;
}

#HomeContainer > .InfoDeck > .col {
  justify-content: center;
}
#HomeContainer > #InfoDeck2 {
  height: 7rem !important;
  margin-top: 0.3rem;
}
#Button {
  text-decoration: none;
  font-size: 0.9rem;
  padding: 0.7rem 1.5rem;
  transition: all 0.2s ease-in;
  border-radius: 0.5rem;
  border-color: transparent;
  font-weight: 500;
  color: #7781d4;
  background: #e7e9fc;
  box-shadow: -4px 8px 15px 1px rgba(0, 0, 0, 0.07);
}

#Button:hover {
  box-shadow: 0px 17px 16px -11px #7781d4;
  transform: translateY(-5px); /*for bouncing */
}

.InfoCard {
  /* Add shadows to create the "card" effect */

  transition: 0.3s;

  justify-content: center;
  height: fit-content;
}

.InfoCard:hover {
  box-shadow: 0 8px 16px 0 rgba(93, 121, 59, 0.2);
}

.InfoCard > .row {
  padding: 0.5rem;
  justify-content: center;
  color: white;
  text-align: center;
}

.InfoCard:hover {
  background-color: white;
  color: #30421a !important;
}

.InfoCard:hover > .row {
  color: #30421a;
}

#Title {
  margin-bottom: 2rem;
  font-size: 54px;
  color: #334b35;

}
.NewsCardsRow {
  padding-left: 3vw;
  padding-right: 3vw;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
