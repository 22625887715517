:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}

.navbar-container.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgb(255, 255, 255);
}

.navbar-container {
  position: relative;
  z-index: 100;
}

.main-nav-row .logo-col .image.small {
  height: 75px;
  max-height: 100%;
  object-fit: cover;
  transition: 0.6s;
}
.main-nav-row .logo-col .image {
  height: 120px;
  max-height: 100%;
  object-fit: cover;
  transition: 0.6s;
}

@media screen and (min-width: 1336px) {
  .main-nav-row {
    position: relative;
    padding-left: 50px;
    padding-right: 25px;
    border-bottom: solid 10px;
    border-color: var(--fixed-primary);
  }

  .main-nav-row .logo-col {
    text-align: center;
    flex: 16.666%;
  }
  .main-nav-row .search-col {
    text-align: center;
    flex: 16.666%;
  }
  .main-nav-row .search-col .link-row .col{
    text-align: center;
    flex: 50%;
  }

  
  .main-nav-row .link-row {
    justify-content: flex-end;
    height: 100%;
    width: 100%;
  }

  .main-nav-row .link-col-container {
    text-align: center;
    justify-content: center;
    margin: auto;
    margin-right: 10px;
    margin-left: 10px;
  }
  .main-nav-row .link-col-container h4 {
    font-weight: 750;
  }

  .main-nav-row .link-col-container .search-col{
    margin-left: 2rem;
  }

  .main-nav-row .hamburger {
    position: relative;
    margin: auto;
    display: none;
  }
}


@media screen and (min-width: 1336px) {
  .main-nav-row .link-col-container {
    flex: 58.3333%;
  }
}


@media screen and (max-width: 1336px) {
  .main-nav-row {
    padding: 10px;
    margin-top: 5px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
    justify-content: center;
  }

  .main-nav-row .logo-col {
    text-align: center;
    width: 200px;
  }

  .main-nav-row .link-row {
    justify-content: center;
  }
  

  .main-nav-row .link-col-container {
    flex: 100%;
    height: 100%;
    text-align: center;
    transition: 1s;
    justify-content: center;
  }

  .main-nav-row .link-col-container.open {
    display: flex;
    position: relative;
    height: 250px;
    width: 100%;
  }
  .main-nav-row .link-col-container.closed {
    height: 0px;
  }

  .main-nav-row .link-col-container.closed * {
    display: none;
  }

  .main-nav-row .hamburger {
    position: relative;
    display: inline;
    color: var(--fixed-primary);
    background-color: white;
    border: 0px;
  }

  .main-nav-row .link-col-container .link-col{
    display: flex;
    justify-content: center;
    flex: 75%;
  }
  .main-nav-row .link-col-container .search-col{
    display: flex;
    flex: 75%;
  }
  .main-nav-row .search-col .link-row .col{
    text-align: center;
    flex: 75%;
  }
}

.main-nav-row .search-col {
  text-align: center;
  padding: 0px;
  justify-content: center;
}
.main-nav-row .request-col {
  text-align: center;
  padding: 0px;
  justify-content: center;
  margin: auto;
}


.main-nav-row a {
  text-decoration: none;
  color: var(--fixed-primary);
}

.main-nav-row a:not([href]) {
  text-decoration: none;
  color: var(--fixed-primary);
}

.main-nav-row a:not([href]):hover {
  text-decoration: none;
  color: var(--fixed-primary);
}

.main-nav-row .link-col-container a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--fixed-secondary);
  transform-origin: bottom right;
  transition: transform 0.75s ease-out;
}
.main-nav-row .link-col-container a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Dropdown Button */
.dropbtn {
  background-color: white;
  color: var(--fixed-primary);

  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

.main-nav-row .link-col-container .dropdown {
  color: var(--fixed-primary) !important;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 200px;
  background-color: rgb(255, 255, 255);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 103;
  transition: ease-in 0.6s;
  font-weight: 450;
  text-align: center;
}



/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  display: block;
  transition: 0.6s;
}

/*Dropdown item animation*/
.dropdown .dropdown-content a{
  padding: 0;
  height: 0px;
  transition: ease-in 0.6s;
}
.dropdown:hover .dropdown-content a{
  height: 50px;
  transition: ease-out 0.6s;
}

.dropdown .dropdown-content p {
  display: none;
  margin: 0;
  opacity: 0;
  transition: ease-out 0.4s;
}

.dropdown:hover .dropdown-content p {
  display: block;
  margin: 0;
  opacity: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  position: relative;
  z-index: 102;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: rgb(255, 255, 255);
  color: var(--fixed-primary);
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.dropbtn::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--fixed-secondary);
  transform-origin: bottom right;
  transition: transform 0.75s ease-out;
}

.main-nav-row .link-col {
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: center;
}
