.contact .info {
    width: 100%;
    background: #fff;
  }
  .contact .info i {
    font-size: 20px;
    background: #ffc451;
    color: #151515;
    float: left;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
  }
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #151515;
  }
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #484848;
  }
  .map{
    margin-top: 50px;
  }
  
  .innerRow h2{

    margin-top:3rem;
  }

  #button-addon2 {
    background-color: var(--fixed-secondary);
    border-color: var(--fixed-secondary);
  }
  #button-addon2:hover {
    background-color: #ffffff;
    border-color: var(--fixed-primary);
    color: var(--fixed-primary);
  }
  #button-addon2:focus {
    outline: none;
    box-shadow: none;
  }