.btn-wrapper #close-btn {
    background-color: red;
    border-color: red;
    padding: 5px 10px; 
}
.btn-wrapper #close-btn:hover{
    background-color: rgb(153, 0, 0);
    border-color: rgb(153, 0, 0);
}
.btn-wrapper #close-btn:focus{
    box-shadow: none;
    outline: none;
    background-color: rgb(153, 0, 0);
    border-color: rgb(153, 0, 0);
}
.btn-wrapper #close-btn:active {
    box-shadow: none;
    outline: none;
    background-color: rgb(153, 0, 0);
    border-color: rgb(153, 0, 0);
    box-shadow: none;
    outline: none;
}