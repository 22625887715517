:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
  --border-width: 3px;
  --animation-speed: 0.5s;
}

.services .wrapper-div {
  width: 300px;
  display: flex;
  justify-content: center;
  margin: 10px;
}

.services .icon-box {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 300px;
  height: 480px;
  text-align: center;
  border: 1px solid #ebebeb;
  padding-top: 80px;
  padding-bottom: 80px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box:hover {
  --animation-speed: 0.5s;
  border-color: var(--fixed-secondary);
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  background-color: var(--fixed-primary);
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: var(--fixed-secondary);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.services .icon-box .image {
  margin: 0 auto;
  width: 256px;
  height: 256px;
  background: var(--fixed-secondary);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

@media screen and (max-width: 1140px) {
  .services .border-animation {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .services .icon-box {
    margin: auto;
    width: 320px;
    height: 480px;
  }
}

.services .icon-box .icon i {
  color: #151515;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}
.services .icon-box h4 {
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 50px;
  word-break: break-word;
  font-family: "Poppins";
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #151515;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  margin-bottom: 15px;
  line-height: 24px;
  font-size: 20px;
}
.services .border-animation:hover {
  --animation-speed: 0.5s;
  border-color: var(--fixed-secondary);
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  background-color: var(--fixed-primary);
}

.services .icon-box:hover {
  /* color: white;
  background-color: var(--fixed-primary); */
}

.services .icon-box:hover h4 {
  color: white;
}
.services .border-animation:hover h4 {
  color: #fff;
}
.services .icon-box:hover p {
  color: white;
}
.services .border-animation:hover p {
  color: #fff;
}

.services .border-animation {
  position: relative;
}

.services .border-animation::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  border: 8px solid rgba(255, 255, 255, 0);

  z-index: 1;
}

.services .border-animation::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  border: 8px solid rgba(255, 255, 255, 0);
}

.services .border-animation:hover::before {
  animation: animateBefore 1s linear forwards;
}
.services .border-animation:hover::after {
  animation: animateAfter 1s linear forwards;
}

@keyframes animateBefore {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  50% {
    width: 100%;
    height: 0%;
    border-top-color: var(--fixed-secondary);
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  100% {
    width: 100%;
    height: 100%;
    border-top-color: var(--fixed-secondary);
    border-right-color: var(--fixed-secondary);
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
}

@keyframes animateAfter {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  50% {
    width: 0%;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: var(--fixed-secondary);
    border-bottom-color: var(--fixed-secondary);
  }

  100% {
    width: 100%;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: var(--fixed-secondary);
    border-bottom-color: var(--fixed-secondary);
  }
}
