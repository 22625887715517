:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}
.row {
  margin-right: 0px;
  margin-left: 0px;
}

.author-main-row {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: var(--fixed-secondary);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.author-main {
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 40px 20px;
  margin-right: auto;
  margin-left: auto;
}

.author-main .img-box {
  align-content: center;
  position: relative;
}

.img-box .author_name {
  text-align: center;
}
.img-box .author_title {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .author-main-row {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .author-main-row .img-box {
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: flex-start;
  }
  .author-main-row .title-col {
    margin: auto;
  }
  .author-main-row .title-col .title-row {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .author-main-row .img-box .image {
    width: 100px;
    object-fit: cover;
    position: relative;
    height: 100px;
    border-radius: 50%;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .author-main-row .img-box .image {
    width: 160px;
    object-fit: cover;
    position: relative;
    height: 160px;
    border-radius: 50%;
  }

  .author-main-row {
    padding: 1rem;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .author-main-row .img-box {
    display: flex;
    align-items: center;
    margin: auto;
  
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: flex-start;
  }
  .author-main-row .title-col {
    margin: auto;
  }
  .author-main-row .title-col .title-row {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

@media screen and (min-width: 991px) {
  .author-main-row {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--fixed-secondary);
  }

  .author-main-row .img-box {
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .author-main-row .title-col {
    margin: auto;
    flex-direction: row;
    margin-bottom: 20px;
  }
  .author-main-row .title-col .title-row {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .author-main-row .title-col .title-row .author_name{
    font-size: 18pt;
    font-weight: 600;
    color: var(--fixed-text-dark);
  }
  .author-main-row .title-col .title-row .editor{
    font-size: 12pt;
    font-weight: 600;
    color: var(--fixed-text-dark);
  }

  .img-box .image {
    width: 256px;
    object-fit: cover;
    position: relative;
    height: 256px;
    border-radius: 50%;
  }
}
