.custom-file-inputfield{
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.custom-file-inputfield-label{
    width: 300px;
    cursor: pointer;
    font-family: "Poppins";
    padding: 10px 30px;
 font-size: 1em;
    font-weight: 700;
    color: white;
    background-color: var(--fixed-secondary);
    transition: 0.3s ease;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.custom-file-inputfield-label:hover{
    transform: translate(0, -6px);
}