.img-container {
  width: 100%;
  height: 100vh;
  height: 100%;
  min-height: 300px;
}

.img-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
