.about-area-2  {
   padding-top: 110px;
   padding-bottom: 45px;
  }
.about-area-2 p {
    font-size: 18px;
    line-height: 30px;
  }
  .about-area-2 .author-info {
    display: flex;
    align-items: center;
  }
  .about-area-2 .author-info img {
    margin-right: 20px;
  }
  .about-area-2 .line-bar {
    position: relative;
  }
  .about-area-2 .line-bar::before {
    position: absolute;
    content: "";
    display: block;
    width: 135px;
    height: 1px;
    left: -70px;
    top: 0px;
    right: 0;
    background: #5b8c51;
  }
  @media (max-width: 575px) {
    .tp-features-list-area {
      display: none;
    }
  }
  
  .tp-features-list {
    display: flex;
    border-top: 1px solid #e4e4e4;
    padding-top: 50px;
    margin-bottom: 90px;
  }
  
  .tp-list-item {
    width: 20%;
    padding: 30px 10px;
    text-align: center;
    background: #fbfaf7;
    transition: 0.3s;
    margin-right: 25px;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1310px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .tp-list-item {
      margin-right: 10px;
    }
  }
  .tp-list-item:last-child {
    margin-right: 0;
  }
  .tp-list-item i {
    font-size: 70px;
    color: #5b8c51;
    margin-bottom: 20px;
    transition: 0.3s;
  }
  .tp-list-item:hover {
    background: #5b8c51;
    box-shadow: 0px 16px 32px 0px rgba(91, 140, 81, 0.3);
    
  }
  .tp-list-item:hover i {
    color: #ffffff;
  }
  .tp-list-item:hover {
    color: #ffffff;
  }
  .features-title {
    font-size: 1rem;
    line-height: 22px;
    margin-bottom: 0;
    transition: 0.3s;
  }

  .tp-section-title {
    font-size: 3rem;
    color: #31512a;
    margin-bottom: 20px;
    line-height: 60px;
  }
  @media (max-width: 450px) {
    .tp-section-title {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tp-section-title {
      font-size: 42px;
      line-height: 50px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .tp-section-title {
      font-size: 40px;
      line-height: 50px;
    }
  }
  
  .tp-section-title-h3 {
    color: #ffffff;
    font-size: 60px;
    line-height: 65px;
  }
  @media (max-width: 450px) {
    .tp-section-title-h3 {
      font-size: 50px;
      line-height: 60px;
    }
  }
  
  .tp-section-title-h3-d {
    color: #9d6b34;
    font-size: 60px;
    line-height: 70px;
  }
  
  .tp-section-title-ab-3 {
    font-size: 58px;
  }
  @media (max-width: 575px) {
    .tp-section-title-ab-3 {
      font-size: 32px;
      line-height: 1.3;
    }
  }

  .orgainc-product {
    z-index: 2;
    background: #fbfaf7;
    padding-top: 120px;
    padding-bottom: 120px;
  }
  
  @media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1300px) and (max-width: 1400px) {
    .organic-product-content {
      padding-left: 60px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1310px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .organic-product-content {
      padding-left: 30px;
    }
  }
  @media (max-width: 575px) {
    .organic-product-content {
      padding-left: 0;
    }
  }
  .project-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .project-bg img {
    width: 100%;
  }
  
  .overlay-bg {
    position: absolute;
    left: 0;
    top: -200px;
    z-index: -1;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1600px) {
    .overlay-bg {
      left: -230px;
      top: -165px;
    }
  }
  @media only screen and (min-width: 1300px) and (max-width: 1400px) {
    .overlay-bg {
      top: -140px;
      left: -300px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1310px) {
    .overlay-bg {
      left: -370px;
      top: -220px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .overlay-bg {
      left: -440px;
      top: -200px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .overlay-bg {
      display: none;
    }
  }
  
  .overlay-bg::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(49, 81, 42, 0.902);
    top: 0;
    left: 0;
    right: 0;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .organic-product-content {
      padding-left: 0;
    }
  }
  
  .organic-product-title {
    font-size: 30px;
    line-height: 28px;
    text-decoration: underline;
    margin-top: 40px;
  }
  
  .organic-image {
    position: relative;
    z-index: 5;
  }
  
  .organic-meta {
    display: flex;
    width: 260px;
    height: 220px;
    background: #fdd062;
    padding: 25px;
    justify-content: center;
    position: absolute;
    top: -100px;
    left: -20px;
    z-index: -1;
  }
  .organic-meta h5 {
    font-size: 60px;
    line-height: 60px;
    margin-right: 20px;
  }
  .organic-meta span {
    font-size: 14px;
    line-height: 20px;
    color: #31512a;
    display: block;
  }
  .organic-meta i {
    font-size: 25px;
    color: #31512a;
    transform: rotate(-315deg);
    position: absolute;
    right: 15px;
    top: 15px;
  }
  
  .organic-features-list {
    padding-left: 25px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .organic-features-list {
      padding-left: 15px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .organic-features-list {
      margin-top: 20px;
      padding-left: 0;
    }
  }
  .organic-features-list a {
    display: inline-block;
    color: #31512a;
    margin-left: 20px;
    transition: 0.3s;
    text-decoration: underline;
    text-decoration-color: transparent;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .organic-features-list a {
      margin-left: 0;
      margin-right: 5px;
    }
  }
  .organic-features-list a:hover {
    text-decoration: underline;
    text-decoration-color: #e4e4e4;
    color: #31512a;
  }
  
  .organic-features-info {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0;
  }
  