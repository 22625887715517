

.partner-main-container{
    background-color: var(--fixed-secondary);
    display: flex;
    justify-content: center;
    margin-block: 5rem;

}
.partner-main-container .partner-main-row {
    width: 90%;
}


/* Columns*/
.partner-main-container .partner-main-row .col{
    padding-inline: 1rem;
} 

.partner-main-container .partner-main-row .partner-col-left{
    padding-block: 3rem;
}

.partner-main-container .partner-main-row .partner-col-right{
    text-align: right;
}

/* Text*/
.partner-main-container .partner-main-row .partner-col-left h2{
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 1.5rem;
}
.partner-main-container .partner-main-row .partner-col-left h3{
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 1rem;
}
.partner-main-container .partner-main-row .partner-col-left p{
    text-align: justify;
    color: var(--fixed-text-color);
    margin-bottom: 2rem;
}


/* Image*/
.partner-main-container .partner-main-row .col .exhibition-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    object-position: left top;

}

/*Link*/

.partner-main-container .partner-main-row .col .go-to-tag{
    text-decoration: none;
    color: var(--fixed-primary);
    font-weight: bold;
    font-size: 22px;
    margin-top: 2rem !important;
}
.partner-main-container .partner-main-row .col .go-to-tag:hover{
    text-decoration: none;
    color: var(--fixed-color-dark);
    
}

/* Mobile*/

@media only screen and (max-width:991px) {
    .partner-main-container .partner-main-row {
        width: 100%;
        flex-direction: column-reverse;
        padding-inline: 1rem;
    }

    .partner-main-container .partner-main-row .col .exhibition-img{
        width: 100%;
        min-width: 600px;
        height: 100%;
        object-fit: fill;
    
    }
    .partner-main-container .partner-main-row .col h2{
        font-size: 30px;
    line-height: 40px;
    
    }
    .partner-main-container .partner-main-row .col h3{
        font-size: 24px;
         line-height: 40px;
    
    }
    
}
@media only screen and (max-width:768px) {
    .partner-main-container .partner-main-row .col .exhibition-img{
        width: 100%;
        min-width: 275px;
        height: 100%;
        object-fit: fill;
    
    }
    
}
