:root {
    --fixed-primary: #6d8c54;
    --fixed-secondary: #f1cf69;
    --fixed-color-dark: #334b35;
  }
  
  .fairs-container {
    width: 100%;
  }
  
  .fairs-container .fairs-icon-box {
    position: relative;
    justify-content: center;
    text-align: left;
    border: 1px solid #ebebeb;
    height: 300px;
    transition: all ease-in-out 0.3s;
    background: transparent;
  }
  /* .fairs-container .fairs-icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: var(--fixed-primary);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
  } */
  
  .fairs-container .fairs-icon-box .fairs-image {
    position: absolute;
    padding: 0px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: var(--fixed-secondary);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
  }
  
  .fairs-container .fairs-icon-box .fairs-image > .image-span{
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .fairs-container .fairs-icon-box .fairs-image .image-span::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1b1a1a;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.6;
  }
  
  
  .fairs-container .fairs-icon-box .icon i {
    color: var(--fixed-color-dark);
    font-size: 28px;
    transition: ease-in-out 0.3s;
  }
  .fairs-container .fairs-icon-box .content-col{
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  
  
  
  .fairs-container .fairs-icon-box h3 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
    color: #ffffff;
    transition: ease-in-out 0.3s;
  }
  
  .fairs-container .fairs-icon-box:hover h3 {
    color: var(--fixed-secondary);
  }
  .fairs-container .fairs-icon-box p {
    line-height: 24px;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 0;
  }
  /* .fairs-container .fairs-icon-box:hover {
    border-color: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    transform: translateX(10px);
    border-bottom-right-radius: 20% 50%;
    border-top-right-radius: 20% 50%;
  } */
  
  .fairs-container .fairs-icon-box .arrow-right {
    position: relative;
    z-index: 2;
    transition: ease-in-out 0.3s;
    color: #ffffff;
  }
  
  .fairs-container .fairs-icon-box:hover .arrow-right {
    color: var(--fixed-secondary);
  }
  
