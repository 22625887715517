@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+2&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#main-container {
  padding-left: 0rem;
  padding-right: 0rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  background-color: white;
}

.row {
  margin-right: 0  !important;
  margin-left: 0  !important;
}

.container {
  padding: 0 !important;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family:  "Poppins", sans-serif;
  font-weight: 500;
}
p {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: #777777;
  margin-bottom: 15px;
}

:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}
#hero-main {
  position: relative;
}

#hero {
  width: 100%;
  height: 100vh;
  background: url(/static/media/istockphoto-506164764-170667a.3c07a513.webp) top center;
  background-size: cover;
  position: relative;
}
#hero:before {
  content: "";
  background: rgba(51, 75, 53, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .main-hero-row{
  width: 100%;
  height: 100%;
}
#hero .col{
  display: -webkit-flex;
  display: flex;
}

#hero .col span{
  margin:auto;
  text-align: center;
}

#hero span p{
  color: rgb(227, 227, 227);
  font-size: 13pt;
}

#hero .search-col{
  background-color: rgba(59, 59, 59, 0.3);
}
#hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
  font-family: "Poppins", sans-serif;
}
#hero h1 span {
  color: #f1cf69;
  color: var(--fixed-secondary);
}
#hero h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 3rem 0 0 0;
  font-size: 24px;
}
#hero .icon-box {

  transition: ease-in-out 0.3s;
  
  height: 100%;
  text-align: center;
  text-decoration: none;
  -webkit-justify-content: center;
          justify-content: center;
}
#hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: #f1cf69;
  color: var(--fixed-secondary);
}
#hero .icon-box h3 {
  padding: 30px 20px;
  width: 100%;
  height: 100%;
  font-weight: 700;
  margin: 10px 0 0 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
  color: white;
  text-decoration: none;
  transition: 0.6s;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
#hero .icon-box:hover h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
  color: #f1cf69;
  color: var(--fixed-secondary);
  text-decoration: none;
  transition: 0.6s;
  border-color: #f1cf69;
  border-color: var(--fixed-secondary);
}

#hero .header-col{
  -webkit-flex-direction: column;
          flex-direction: column;
}

#hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}
#hero .icon-box h3 a:hover {
  color: #f1cf69;
  color: var(--fixed-secondary);
}
#hero .icon-box:hover {
  border-color: #f1cf69;
  border-color: var(--fixed-secondary);
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
  #hero .header-col{
    padding-inline: 3rem ;
  }
  
}
@media (max-width: 768px) {
  #hero {
    height: auto;
    padding-top:5rem;
    padding-bottom:5rem;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 20px;
    line-height: 24px;
  }
  #hero .search-col{
    display: none;
  }
}

:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
  --search-comp-height-large: 150px;
  --search-comp-height-med: 100px;
  --back-color: rgba(
    241,
    207,
    105,
    0.95
  ); /* Black with a little bit see-through */
  --input-color: rgba(0, 0, 0, 0); /*Transparent*/
  --hover-color-btn: var(--fixed-primary);
  --transition-time: 0.6s;
}

/* #region Main Container */
.searchbar-main-container {
  transition: 0.6s;
  transition: var(--transition-time);
}

.searchbar-main-container.closed {
  /* display: none; */
  height: 0;
}

.searchbar-main-container.closed * {
  display: none;
}

.searchbar-main-container.open {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 0;
  background-color: rgba(
    241,
    207,
    105,
    0.95
  );
  background-color: var(--back-color);
}

.searchbar-main-container.open .search-lbl {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.searchbar-main-container.open .search-form {
  position: relative;
  margin: auto;
}

/* #endregion */

/* #region Close Button */

.searchbar-main-container.open .close-btn {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  background-color: rgba(0, 0, 0, 0);
  background-color: var(--input-color);
  border: 0px;
  margin-top: 10px;
  margin-right: 10px;
  transition: 0.6s;
  transition: var(--transition-time);
}

.searchbar-main-container.open .close-btn:hover {
  background-color: #6d8c54;
  background-color: var(--hover-color-btn);
}

/* #endregion */

/* Required for aligning text-box and button*/
.searchbar-main-container.open .input-box {
  display: -webkit-flex;
  display: flex;
}

.input-box{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

/*text-box */
.input-box .search-input {
  width: 100%;
  height: 150px;
  background-color: rgba(0, 0, 0, 0);
  background-color: var(--input-color);
  font-size: 32pt;
  font-family: "Poppins";
  color: white;
  border: 0px;
  border-bottom: 3px solid;
  border-color: rgb(255, 255, 255);
  text-align: center;
  transition: 0.6s;
  transition: var(--transition-time);
}

@media only screen and (max-width: 1180px) {
  .input-box .search-input {
    width: 200px;
    height: 100px;
    height: var(--search-comp-height-med);
  }
  .searchbar-main-container.open .input-box .search-btn {
    width: 80px;
    height: 100px;
    height: var(--search-comp-height-med);
  }
}

.input-box .search-input:focus {
  outline: none !important;
  border-color: #6d8c54;
  border-color: var(--hover-color-btn);
  color: #6d8c54;
  color: var(--hover-color-btn);
}

.input-box .search-input::-webkit-input-placeholder {
  color: white;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transition: var(--transition-time);
  transition: var(--transition-time);
}

.input-box .search-input:-ms-input-placeholder {
  color: white;
  -ms-transition: 0.6s;
  transition: 0.6s;
  -ms-transition: var(--transition-time);
  transition: var(--transition-time);
}

.input-box .search-input::placeholder {
  color: white;
  transition: 0.6s;
  transition: var(--transition-time);
}

.input-box .search-input:focus::-webkit-input-placeholder {
  outline: none !important;
  border-color: #6d8c54;
  border-color: var(--hover-color-btn);
  color: #6d8c54;
  color: var(--hover-color-btn);
}

.input-box .search-input:focus:-ms-input-placeholder {
  outline: none !important;
  border-color: #6d8c54;
  border-color: var(--hover-color-btn);
  color: #6d8c54;
  color: var(--hover-color-btn);
}

.input-box .search-input:focus::placeholder {
  outline: none !important;
  border-color: #6d8c54;
  border-color: var(--hover-color-btn);
  color: #6d8c54;
  color: var(--hover-color-btn);
}


.input-box.hero .search-input:focus {
  outline: none !important;
  border-color: #f1cf69;
  border-color: var(--fixed-secondary);
  color: #f1cf69;
  color: var(--fixed-secondary);
}

.input-box.hero .search-input::-webkit-input-placeholder {
  color: white;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transition: var(--transition-time);
  transition: var(--transition-time);
}

.input-box.hero .search-input:-ms-input-placeholder {
  color: white;
  -ms-transition: 0.6s;
  transition: 0.6s;
  -ms-transition: var(--transition-time);
  transition: var(--transition-time);
}

.input-box.hero .search-input::placeholder {
  color: white;
  transition: 0.6s;
  transition: var(--transition-time);
}

.input-box.hero .search-input:focus::-webkit-input-placeholder {
  outline: none !important;
  border-color: #f1cf69;
  border-color: var(--fixed-secondary);
  color: rgb(212, 212, 212);
}

.input-box.hero .search-input:focus:-ms-input-placeholder {
  outline: none !important;
  border-color: #f1cf69;
  border-color: var(--fixed-secondary);
  color: rgb(212, 212, 212);
}

.input-box.hero .search-input:focus::placeholder {
  outline: none !important;
  border-color: #f1cf69;
  border-color: var(--fixed-secondary);
  color: rgb(212, 212, 212);
}

/*submit button*/
.input-box .search-btn {
  height: 150px;
  height: var(--search-comp-height-large);
  width: 80px;
  background-color: rgba(0, 0, 0, 0);
  background-color: var(--input-color);
  border: 0px solid;
  border-bottom: 3px solid;
  border-radius: 0px;
  transition: 0.6s;
  transition: var(--transition-time);
  outline: none !important;
}

.input-box .search-btn:hover {
  width: 120px;
  background-color: rgba(0, 0, 0, 0);
  background-color: var(--input-color);
  border-color: #6d8c54;
  border-color: var(--hover-color-btn);
  color: #6d8c54;
  color: var(--hover-color-btn);
}

.searchbar-main-container.open .input-box .search-btn:focus {
  box-shadow: none;
  border-color: #6d8c54;
  border-color: var(--hover-color-btn);
  background-color: rgba(0, 0, 0, 0);
  background-color: var(--input-color);
}

.searchbar-main-container.open .input-box .search-btn:active {
  border-color: #6d8c54;
  border-color: var(--hover-color-btn);
  background-color: #334b35;
  background-color: var(--fixed-color-dark);
}

.input-box.hero .search-btn:hover {
  width: 120px;
  background-color: transparent;
  border-color: #f1cf69;
  border-color: var(--fixed-secondary);
  color: #f1cf69;
  color: var(--fixed-secondary);
}

.searchbar-main-container.open .input-box.hero .search-btn:focus {
  box-shadow: none;
  border-color: #f1cf69;
  border-color: var(--fixed-secondary);
  background-color: #f1cf69;
  background-color: var(--fixed-secondary);
}

.searchbar-main-container.open .input-box.hero .search-btn:active {
  border-color: #f1cf69;
  border-color: var(--fixed-secondary);
  background-color: #f1cf69;
  background-color: var(--fixed-secondary);
}

.title {
  font-family: "Poppins";
  color: #6d8c54;
  color: var(--fixed-primary);
}

:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}

#features {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f1cf69;
  background-color: var(--fixed-secondary);
  position: relative;
  overflow: auto;
}

#features .bg-img {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.1;
}

#features .icon-box {
  padding-left: 15px;
}
#features .icon-box h3 {
  color: #334b35;
  color: var(--fixed-color-dark);
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}
#features .icon-box i {
  font-size: 48px;
  float: left;
  color: #ffc451;
}
#features .icon-box p {
  font-size: 15px;
  color: #474747;
  margin-left: 60px;
}
#features .image {
  background-color: #6d8c54;
  background-color: var(--fixed-primary);
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}

#features .image img {
  position: absolute;
  top: -25px;
  left: -25px;
}

:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
  --border-width: 3px;
  --animation-speed: 0.5s;
}

.services .wrapper-div {
  width: 300px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px;
}

.services .icon-box {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 300px;
  height: 480px;
  text-align: center;
  border: 1px solid #ebebeb;
  padding-top: 80px;
  padding-bottom: 80px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.services .icon-box:hover {
  --animation-speed: 0.5s;
  border-color: #f1cf69;
  border-color: var(--fixed-secondary);
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  background-color: #6d8c54;
  background-color: var(--fixed-primary);
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #f1cf69;
  background: var(--fixed-secondary);
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.services .icon-box .image {
  margin: 0 auto;
  width: 256px;
  height: 256px;
  background: #f1cf69;
  background: var(--fixed-secondary);
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

@media screen and (max-width: 1140px) {
  .services .border-animation {
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .services .icon-box {
    margin: auto;
    width: 320px;
    height: 480px;
  }
}

.services .icon-box .icon i {
  color: #151515;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}
.services .icon-box h4 {
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 50px;
  word-break: break-word;
  font-family: "Poppins";
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #151515;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  margin-bottom: 15px;
  line-height: 24px;
  font-size: 20px;
}
.services .border-animation:hover {
  --animation-speed: 0.5s;
  border-color: #f1cf69;
  border-color: var(--fixed-secondary);
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  background-color: #6d8c54;
  background-color: var(--fixed-primary);
}

.services .icon-box:hover {
  /* color: white;
  background-color: var(--fixed-primary); */
}

.services .icon-box:hover h4 {
  color: white;
}
.services .border-animation:hover h4 {
  color: #fff;
}
.services .icon-box:hover p {
  color: white;
}
.services .border-animation:hover p {
  color: #fff;
}

.services .border-animation {
  position: relative;
}

.services .border-animation::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  border: 8px solid rgba(255, 255, 255, 0);

  z-index: 1;
}

.services .border-animation::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  border: 8px solid rgba(255, 255, 255, 0);
}

.services .border-animation:hover::before {
  -webkit-animation: animateBefore 1s linear forwards;
          animation: animateBefore 1s linear forwards;
}
.services .border-animation:hover::after {
  -webkit-animation: animateAfter 1s linear forwards;
          animation: animateAfter 1s linear forwards;
}

@-webkit-keyframes animateBefore {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  50% {
    width: 100%;
    height: 0%;
    border-top-color: #f1cf69;
    border-top-color: var(--fixed-secondary);
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  100% {
    width: 100%;
    height: 100%;
    border-top-color: #f1cf69;
    border-top-color: var(--fixed-secondary);
    border-right-color: #f1cf69;
    border-right-color: var(--fixed-secondary);
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
}

@keyframes animateBefore {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  50% {
    width: 100%;
    height: 0%;
    border-top-color: #f1cf69;
    border-top-color: var(--fixed-secondary);
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  100% {
    width: 100%;
    height: 100%;
    border-top-color: #f1cf69;
    border-top-color: var(--fixed-secondary);
    border-right-color: #f1cf69;
    border-right-color: var(--fixed-secondary);
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
}

@-webkit-keyframes animateAfter {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  50% {
    width: 0%;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: #f1cf69;
    border-left-color: var(--fixed-secondary);
    border-bottom-color: #f1cf69;
    border-bottom-color: var(--fixed-secondary);
  }

  100% {
    width: 100%;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: #f1cf69;
    border-left-color: var(--fixed-secondary);
    border-bottom-color: #f1cf69;
    border-bottom-color: var(--fixed-secondary);
  }
}

@keyframes animateAfter {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  50% {
    width: 0%;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: #f1cf69;
    border-left-color: var(--fixed-secondary);
    border-bottom-color: #f1cf69;
    border-bottom-color: var(--fixed-secondary);
  }

  100% {
    width: 100%;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: #f1cf69;
    border-left-color: var(--fixed-secondary);
    border-bottom-color: #f1cf69;
    border-bottom-color: var(--fixed-secondary);
  }
}

:root {
    --fixed-primary: #6d8c54;
    --fixed-secondary: #f1cf69;
    --fixed-color-dark: #334b35;
    --border-width: 3px;
    --animation-speed: 0.5s;
  }
  
  .header-section-wrap {
    color: #6d8c54;
    color: var(--fixed-primary);
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  
  .header-section-wrap i{
      font-size: 3rem;
  }
  
  .header-section-title{
    font-size: 4rem;
    color: #31512a;
    margin-bottom: 20px;
    line-height: 60px;
  }
  .header-section-subtitle{
    font-size: 1.5rem;
    color: #31512a;
    margin-bottom: 20px;
    padding-inline: 3rem;
    line-height: 30px;
  }


  @media (max-width: 450px) {
    .header-section-title {
      font-size: 30px;
      line-height: 40px;
    }
    .header-section-subtitle{
      font-size: 1.5rem;
      color: #31512a;
      margin-bottom: 20px;
      padding-inline: 1rem;
      line-height: 30px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-section-title {
      font-size: 42px;
      line-height: 50px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-section-title {
      font-size: 40px;
      line-height: 50px;
    }
    .header-section-subtitle{
      font-size: 1.5rem;
      color: #31512a;
      margin-bottom: 20px;
      padding-inline: 1rem;
      line-height: 30px;
    }
  }
.contact .info {
    width: 100%;
    background: #fff;
  }
  .contact .info i {
    font-size: 20px;
    background: #ffc451;
    color: #151515;
    float: left;
    width: 44px;
    height: 44px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
  }
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #151515;
  }
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #484848;
  }
  .map{
    margin-top: 50px;
  }
  
  .innerRow h2{

    margin-top:3rem;
  }

  #button-addon2 {
    background-color: var(--fixed-secondary);
    border-color: var(--fixed-secondary);
  }
  #button-addon2:hover {
    background-color: #ffffff;
    border-color: var(--fixed-primary);
    color: var(--fixed-primary);
  }
  #button-addon2:focus {
    outline: none;
    box-shadow: none;
  }
.about-area-2  {
   padding-top: 110px;
   padding-bottom: 45px;
  }
.about-area-2 p {
    font-size: 18px;
    line-height: 30px;
  }
  .about-area-2 .author-info {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .about-area-2 .author-info img {
    margin-right: 20px;
  }
  .about-area-2 .line-bar {
    position: relative;
  }
  .about-area-2 .line-bar::before {
    position: absolute;
    content: "";
    display: block;
    width: 135px;
    height: 1px;
    left: -70px;
    top: 0px;
    right: 0;
    background: #5b8c51;
  }
  @media (max-width: 575px) {
    .tp-features-list-area {
      display: none;
    }
  }
  
  .tp-features-list {
    display: -webkit-flex;
    display: flex;
    border-top: 1px solid #e4e4e4;
    padding-top: 50px;
    margin-bottom: 90px;
  }
  
  .tp-list-item {
    width: 20%;
    padding: 30px 10px;
    text-align: center;
    background: #fbfaf7;
    transition: 0.3s;
    margin-right: 25px;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1310px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .tp-list-item {
      margin-right: 10px;
    }
  }
  .tp-list-item:last-child {
    margin-right: 0;
  }
  .tp-list-item i {
    font-size: 70px;
    color: #5b8c51;
    margin-bottom: 20px;
    transition: 0.3s;
  }
  .tp-list-item:hover {
    background: #5b8c51;
    box-shadow: 0px 16px 32px 0px rgba(91, 140, 81, 0.3);
    
  }
  .tp-list-item:hover i {
    color: #ffffff;
  }
  .tp-list-item:hover {
    color: #ffffff;
  }
  .features-title {
    font-size: 1rem;
    line-height: 22px;
    margin-bottom: 0;
    transition: 0.3s;
  }

  .tp-section-title {
    font-size: 3rem;
    color: #31512a;
    margin-bottom: 20px;
    line-height: 60px;
  }
  @media (max-width: 450px) {
    .tp-section-title {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tp-section-title {
      font-size: 42px;
      line-height: 50px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .tp-section-title {
      font-size: 40px;
      line-height: 50px;
    }
  }
  
  .tp-section-title-h3 {
    color: #ffffff;
    font-size: 60px;
    line-height: 65px;
  }
  @media (max-width: 450px) {
    .tp-section-title-h3 {
      font-size: 50px;
      line-height: 60px;
    }
  }
  
  .tp-section-title-h3-d {
    color: #9d6b34;
    font-size: 60px;
    line-height: 70px;
  }
  
  .tp-section-title-ab-3 {
    font-size: 58px;
  }
  @media (max-width: 575px) {
    .tp-section-title-ab-3 {
      font-size: 32px;
      line-height: 1.3;
    }
  }

  .orgainc-product {
    z-index: 2;
    background: #fbfaf7;
    padding-top: 120px;
    padding-bottom: 120px;
  }
  
  @media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1300px) and (max-width: 1400px) {
    .organic-product-content {
      padding-left: 60px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1310px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .organic-product-content {
      padding-left: 30px;
    }
  }
  @media (max-width: 575px) {
    .organic-product-content {
      padding-left: 0;
    }
  }
  .project-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .project-bg img {
    width: 100%;
  }
  
  .overlay-bg {
    position: absolute;
    left: 0;
    top: -200px;
    z-index: -1;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1600px) {
    .overlay-bg {
      left: -230px;
      top: -165px;
    }
  }
  @media only screen and (min-width: 1300px) and (max-width: 1400px) {
    .overlay-bg {
      top: -140px;
      left: -300px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1310px) {
    .overlay-bg {
      left: -370px;
      top: -220px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .overlay-bg {
      left: -440px;
      top: -200px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .overlay-bg {
      display: none;
    }
  }
  
  .overlay-bg::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(49, 81, 42, 0.902);
    top: 0;
    left: 0;
    right: 0;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .organic-product-content {
      padding-left: 0;
    }
  }
  
  .organic-product-title {
    font-size: 30px;
    line-height: 28px;
    text-decoration: underline;
    margin-top: 40px;
  }
  
  .organic-image {
    position: relative;
    z-index: 5;
  }
  
  .organic-meta {
    display: -webkit-flex;
    display: flex;
    width: 260px;
    height: 220px;
    background: #fdd062;
    padding: 25px;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    top: -100px;
    left: -20px;
    z-index: -1;
  }
  .organic-meta h5 {
    font-size: 60px;
    line-height: 60px;
    margin-right: 20px;
  }
  .organic-meta span {
    font-size: 14px;
    line-height: 20px;
    color: #31512a;
    display: block;
  }
  .organic-meta i {
    font-size: 25px;
    color: #31512a;
    -webkit-transform: rotate(-315deg);
            transform: rotate(-315deg);
    position: absolute;
    right: 15px;
    top: 15px;
  }
  
  .organic-features-list {
    padding-left: 25px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .organic-features-list {
      padding-left: 15px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .organic-features-list {
      margin-top: 20px;
      padding-left: 0;
    }
  }
  .organic-features-list a {
    display: inline-block;
    color: #31512a;
    margin-left: 20px;
    transition: 0.3s;
    text-decoration: underline;
    -webkit-text-decoration-color: transparent;
            text-decoration-color: transparent;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .organic-features-list a {
      margin-left: 0;
      margin-right: 5px;
    }
  }
  .organic-features-list a:hover {
    text-decoration: underline;
    -webkit-text-decoration-color: #e4e4e4;
            text-decoration-color: #e4e4e4;
    color: #31512a;
  }
  
  .organic-features-info {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0;
  }
  
#Popular {
  margin-top: 8rem;
  margin-bottom: 2rem;
}

#Popular > #newsrow > #Title {
  padding: 1rem;
}

/* #Title {
  font-size: 2.5rem;
  font-weight: 500;
  font-family: "Libre Franklin", sans-serif;
} */

#Carts {
  margin-left: 1.9rem;
  margin-right: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: -8px 8px 6px -6px #999;
  width: 20rem;
}
@media (max-width: 680px) {
  div#Carts {
    margin: 2rem;
  }
}

#Carts > .card-img-top {
  height: 17rem;
  width: 100%;
}

#cards-row {
  margin-top: 2rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

#cards-row-product {
  margin-top: 2rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

#HomeContainer {
  padding-left: 0rem;
  padding-right: 0rem;
  background-color: #fffffd;
}
#HomeContainer > #SliderRow {
  padding-left: 0rem;
  padding-right: 0rem;
  background-color: #fffffd;
  width: 100%;
  margin-right: 0rem;
  margin-left: 0rem;
  height: 100%;
}

#HomeContainer > .InfoDeck {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
  padding-right: 0rem;
  background-color: #30421a;
  width: 100%;
  height: 10rem;
  margin-top: 2rem;
  margin-right: 0rem;
  margin-left: 0rem;
}

#HomeContainer > .InfoDeck > .col {
  -webkit-justify-content: center;
          justify-content: center;
}
#HomeContainer > #InfoDeck2 {
  height: 7rem !important;
  margin-top: 0.3rem;
}
#Button {
  text-decoration: none;
  font-size: 0.9rem;
  padding: 0.7rem 1.5rem;
  transition: all 0.2s ease-in;
  border-radius: 0.5rem;
  border-color: transparent;
  font-weight: 500;
  color: #7781d4;
  background: #e7e9fc;
  box-shadow: -4px 8px 15px 1px rgba(0, 0, 0, 0.07);
}

#Button:hover {
  box-shadow: 0px 17px 16px -11px #7781d4;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px); /*for bouncing */
}

.InfoCard {
  /* Add shadows to create the "card" effect */

  transition: 0.3s;

  -webkit-justify-content: center;

          justify-content: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.InfoCard:hover {
  box-shadow: 0 8px 16px 0 rgba(93, 121, 59, 0.2);
}

.InfoCard > .row {
  padding: 0.5rem;
  -webkit-justify-content: center;
          justify-content: center;
  color: white;
  text-align: center;
}

.InfoCard:hover {
  background-color: white;
  color: #30421a !important;
}

.InfoCard:hover > .row {
  color: #30421a;
}

#Title {
  margin-bottom: 2rem;
  font-size: 54px;
  color: #334b35;

}
.NewsCardsRow {
  padding-left: 3vw;
  padding-right: 3vw;
  margin-top: 2rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}
.productHeader {
  padding: 10px;
}
#productrow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.flex-col{
  margin-bottom: 3rem;
  width: 100%;
}

#product-mid-row {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

#RequestCol > .row {
  -webkit-justify-content: center;
          justify-content: center;
}
#tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  padding-top: 1rem;
  width: 100%;
}
#tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  border-style: none;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 15px 10px 10px 15px;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

#tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

#tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

#tag:hover {
  transition: ease-in-out 0.5s;
  background-color: #f1cf69;
  background-color: var(--fixed-secondary);
  color: white;
}

#tag:hover::after {
  transition: ease-in-out 0.5s;
  border-left-color: #f1cf69;
  border-left-color: var(--fixed-secondary);
}

@media screen and (min-width: 1440px) {
  #ProductTopRow {
    margin-right: 10%;
    margin-left: 10%;
    padding: 1rem;
  }
}
@media screen and (max-width: 992px) {
  #ProductTopRow {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.CardsRow {
  padding-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 2rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: left;
}

#description-span {
  text-align: left;
}
#product-mid-row #titlerow {
  margin-top: 2rem;
  margin-left: 3vw;
  margin-right: 3vw;
}

#product-mid-row .description-row {
  margin-left: 3vw;
  margin-right: 3vw;
}

.tp-section-wrap span i {
  font-size: 30px;
  color: #5b8c51;
  margin-bottom: 20px;
}
.tp-section-wrap p {
  font-size: 16px;
  line-height: 26px;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.tp-section-wrap-h3 p {
  font-size: 18px;
  line-height: 30px;
}

.project-details-image {
  position: relative;
}

.project-d-info {
  position: absolute;
  right: -65%;
  top: 40px;
  max-width: 370px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  padding: 40px;
  min-height: 355px;
  background: #ffffff;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-d-info {
    right: -30%;
    top: 100px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px),
  (max-width: 575px) {
  .project-d-info {
    display: none;
  }
}

.project-d-info-title {
  /* font-size: 24px; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  line-height: 20px;
  position: relative;
  color: #31512a;
  margin-top: 0px;
  font-weight: 500;
  transition: all 0.3s ease-out 0s;
}
.project-d-info-title h5{
  margin-bottom: 0 !important;
}
.project-info-lists {
  margin-top: 40px;
}
.project-d-info-title::before {
  position: absolute;
  content: "";
  background: #e3e3e3;
  bottom: -15px;
  width: 100%;
  height: 1px;
}

.d-info-item {
  height: 80px;
  width: 50%;
  float: left;
  margin-bottom: 20px;
}
.d-info-item h6 {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0;
  color: #31512a;
}
.d-info-item p {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0;
}

.project-d-bt {
  margin-top: 30 px;
}

.project-d-btn .fa-share {
  font-size: 18px;
  color: #777777;
  height: 50px;
  width: 50px;
  border: 2px solid #e7e7e7;
  line-height: 40px;
  text-align: center;
  margin-right: 30px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  margin-left: 20px;
  margin-top: 30 px;
}

.p-d-btn {
  padding: 15px 30px;
}
.p-d-btn i {
  margin-left: 10px;
  font-size: 20px;
}

.tp-btn-h1 {
  width: 100%;
  background: #5b8c51;
  border: medium none;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 1;
  margin-bottom: 0;
  padding: 20px 40px;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  transition: 0.3s;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  letter-spacing: 0;
}
.tp-btn-h1:hover {
  background: #ffffff;
  color: #5b8c51;
  border-color: #5b8c51;
}

.project-d-descriptiopn {
  position: relative;
  margin-top: 6rem;
  margin-bottom: 8rem;
}
.project-d-descriptiopn .description-row{
  padding-left: 10%;
  padding-right: 10%;
}
@media only screen and (max-width:991px){

  .project-d-descriptiopn .description-row {
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}


.project-d-descriptiopn::before{
  content: "";
  width: 50%;
  position: absolute;
  top: -3rem;
  height: 10px;
  background: linear-gradient(90deg, #6d8c54,white);
  background: linear-gradient(90deg, var(--fixed-primary),white);
}
.project-d-descriptiopn::after{
  content: "";
  width: 50%;
  position: absolute;
  bottom: -3rem;
  right: 0;
  height: 10px;
  background: linear-gradient(-90deg, #6d8c54,white);
  background: linear-gradient(-90deg, var(--fixed-primary),white);
}

.project-d-descriptiopn .description-title {
  color: #334b35;
  color: var(--fixed-color-dark);
}

.project-d-descriptiopn h1 {
  font-size: 1rem;
}
.project-d-descriptiopn h2, .project-d-descriptiopn h {
  color: var(--fixed-secondary-dark);
}

.display-request-col h2{
  font-size: xxx-large;
}

.project-d-descriptiopn p {
  font-size: 18px;
  line-height: 30px;
  white-space: break-spaces;
  text-align: justify;
}

.p-quote {
  position: absolute;
  font-size: 60px;
  line-height: 100px;
  height: 100px;
  width: 100px;
  background: #5b8c51;
  color: #ffffff;
  display: block;
  font-family: "Prata", serif;
  text-align: center;
  top: -10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .p-quote {
    top: 8px;
  }
}

.floating-section-rows .display-request-col{
  position: relative;
  z-index: 2;
  margin-top: 10%;
  margin-left: 4rem;
  color: #334b35;
  color: var(--fixed-color-dark);
}
.floating-section-rows .display-request-col p{
  text-align: justify;
}

.display-request-img-col .display-request-img-wrapper{
  position: relative;

}
.display-request-img-col .display-request-img-wrapper>img{
  position: absolute;
  right: 0;
  width: 150%;
  max-height: 1000px;

}
.request-form-img-col .request-form-img-wrapper{
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;

}
.request-form-img-col .request-form-img-wrapper>img{
  object-fit: cover;
  rotate: 30deg;
  width: 100%;
 

}


@media only screen and (max-width:991px){
  .floating-section-rows .display-request-col{
    margin-left: 0;
  }
  .display-request-img-col .display-request-img-wrapper{
    display: none;
    -webkit-flex: 0 1;
            flex: 0 1;
  
  }

  .request-form-img-col .request-form-img-wrapper{
    display: none;
    -webkit-flex: 0 1;
            flex: 0 1;
  }

}
:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}

.news-container {
  width: 100%;
}

.news-container .news-icon-box {
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: left;
  border: 1px solid #ebebeb;
  height: 300px;
  transition: all ease-in-out 0.3s;
  background: #6d8c54;
  background: var(--fixed-primary);
}
.news-container .news-icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #6d8c54;
  background: var(--fixed-primary);
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.news-container .news-icon-box .news-image {
  position: absolute;
  padding: 0px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: #f1cf69;
  background: var(--fixed-secondary);
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.news-container .news-icon-box .news-image > .image-span{
  width: 100%;
  height: 100%;
  position: relative;
}

.news-container .news-icon-box .news-image .image-span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1b1a1a;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.6;
}


.news-container .news-icon-box .icon i {
  color: #334b35;
  color: var(--fixed-color-dark);
  font-size: 28px;
  transition: ease-in-out 0.3s;
}
.news-container .news-icon-box .content-col{
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}




.news-container .news-icon-box h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #ffffff;
  transition: ease-in-out 0.3s;
}

.news-container .news-icon-box:hover h3 {
  color: #f1cf69;
  color: var(--fixed-secondary);
}
.news-container .news-icon-box p {
  line-height: 24px;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 0;
}
.news-container .news-icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  border-bottom-right-radius: 20% 50%;
  border-top-right-radius: 20% 50%;
}

.news-container .news-icon-box .arrow-right {
  position: relative;
  z-index: 2;
  transition: ease-in-out 0.3s;
  color: #ffffff;
}

.news-container .news-icon-box:hover .arrow-right {
  color: #f1cf69;
  color: var(--fixed-secondary);
}


.row.arrow-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.arrow-row .arrow-btn{
  background-color: #f1cf69;
  background-color: var(--fixed-secondary);
  border:none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.arrow-row .page-count{
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0;
  -webkit-align-items: center;
          align-items: center;
  font-weight: bold;
}
.table-wrapper{
 
  font-family: 'Poppins';
    /* padding-right:1rem;
    padding-left:1rem;
    padding-top:1rem;
    padding-bottom:1rem; */
    margin-top: 2rem;
    border-radius: 10px;
    /* background-color: var(--fixed-color-dark); */
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}


.table-wrapper:has( .toolbar-main-container) {
  border-radius: 0;
}
.table-wrapper > .fZxLXy{
  border-radius: none !important;
}

.row .jvsFmi{
  border-radius: 0;
}

.row  .rdt_Table {

    height: 600px;

    padding-right: 0;
}

.row .rdt_Table .rdt_TableBody{
  overflow-y: scroll;
}
.rdt_Table .rdt_TableHead{

   z-index: 0 !important;
}


/* width */
::-webkit-scrollbar {
    width: 0;
  }

  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: var(--fixed-color-dark);
    width: 0;
   
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--fixed-secondary);
  }
.toolbar-main-container{
    background-color: var(--fixed-primary);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-top: 10px !important;
 
}

.toolbar-main-container h4, .toolbar-main-container label{
    color: var(--fixed-text-white);
}

.toolbar-main-container .toolbar-btn{
    padding: 3px 3px;
    width: 100px;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    outline: none;

    font-size: small !important;
}
.toolbar-main-container  .toolbar-btn:focus{
    outline: none;
    box-shadow: none;
}
.toolbar-main-container  .toolbar-btn:active{
    outline: none;
    box-shadow: none;
}
#toolbar-add-btn{
    background-color: green;
    border-color: green;
}
#toolbar-add-btn:hover{
    background-color: white;
    color: green;

}
#toolbar-delete-btn{
    background-color: rgb(197, 52, 52);
    border-color: rgb(197, 52, 52);
}
#toolbar-delete-btn:hover{
    background-color: white;
    color: rgb(197, 52, 52);
    border-color: rgb(197, 52, 52);
}
.latest-req-container h2{
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-size: 42px;
    color: #31512a;
}


.partner-main-container{
    background-color: var(--fixed-secondary);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-block: 5rem;

}
.partner-main-container .partner-main-row {
    width: 90%;
}


/* Columns*/
.partner-main-container .partner-main-row .col{
    padding-inline: 1rem;
} 

.partner-main-container .partner-main-row .partner-col-left{
    padding-block: 3rem;
}

.partner-main-container .partner-main-row .partner-col-right{
    text-align: right;
}

/* Text*/
.partner-main-container .partner-main-row .partner-col-left h2{
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 1.5rem;
}
.partner-main-container .partner-main-row .partner-col-left h3{
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 1rem;
}
.partner-main-container .partner-main-row .partner-col-left p{
    text-align: justify;
    color: var(--fixed-text-color);
    margin-bottom: 2rem;
}


/* Image*/
.partner-main-container .partner-main-row .col .exhibition-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    object-position: left top;

}

/*Link*/

.partner-main-container .partner-main-row .col .go-to-tag{
    text-decoration: none;
    color: var(--fixed-primary);
    font-weight: bold;
    font-size: 22px;
    margin-top: 2rem !important;
}
.partner-main-container .partner-main-row .col .go-to-tag:hover{
    text-decoration: none;
    color: var(--fixed-color-dark);
    
}

/* Mobile*/

@media only screen and (max-width:991px) {
    .partner-main-container .partner-main-row {
        width: 100%;
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
        padding-inline: 1rem;
    }

    .partner-main-container .partner-main-row .col .exhibition-img{
        width: 100%;
        min-width: 600px;
        height: 100%;
        object-fit: fill;
    
    }
    .partner-main-container .partner-main-row .col h2{
        font-size: 30px;
    line-height: 40px;
    
    }
    .partner-main-container .partner-main-row .col h3{
        font-size: 24px;
         line-height: 40px;
    
    }
    
}
@media only screen and (max-width:768px) {
    .partner-main-container .partner-main-row .col .exhibition-img{
        width: 100%;
        min-width: 275px;
        height: 100%;
        object-fit: fill;
    
    }
    
}

:root {
    --fixed-primary: #6d8c54;
    --fixed-secondary: #f1cf69;
    --fixed-color-dark: #334b35;
  }
  
  .fairs-container {
    width: 100%;
  }
  
  .fairs-container .fairs-icon-box {
    position: relative;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left;
    border: 1px solid #ebebeb;
    height: 300px;
    transition: all ease-in-out 0.3s;
    background: transparent;
  }
  /* .fairs-container .fairs-icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: var(--fixed-primary);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
  } */
  
  .fairs-container .fairs-icon-box .fairs-image {
    position: absolute;
    padding: 0px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: #f1cf69;
    background: var(--fixed-secondary);
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
  }
  
  .fairs-container .fairs-icon-box .fairs-image > .image-span{
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .fairs-container .fairs-icon-box .fairs-image .image-span::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1b1a1a;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.6;
  }
  
  
  .fairs-container .fairs-icon-box .icon i {
    color: #334b35;
    color: var(--fixed-color-dark);
    font-size: 28px;
    transition: ease-in-out 0.3s;
  }
  .fairs-container .fairs-icon-box .content-col{
    position: relative;
    z-index: 2;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
  }
  
  
  
  
  .fairs-container .fairs-icon-box h3 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
    color: #ffffff;
    transition: ease-in-out 0.3s;
  }
  
  .fairs-container .fairs-icon-box:hover h3 {
    color: #f1cf69;
    color: var(--fixed-secondary);
  }
  .fairs-container .fairs-icon-box p {
    line-height: 24px;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 0;
  }
  /* .fairs-container .fairs-icon-box:hover {
    border-color: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    transform: translateX(10px);
    border-bottom-right-radius: 20% 50%;
    border-top-right-radius: 20% 50%;
  } */
  
  .fairs-container .fairs-icon-box .arrow-right {
    position: relative;
    z-index: 2;
    transition: ease-in-out 0.3s;
    color: #ffffff;
  }
  
  .fairs-container .fairs-icon-box:hover .arrow-right {
    color: #f1cf69;
    color: var(--fixed-secondary);
  }
  




.fairs-carousel  .fairs-icon-box {
  height: 525px;
}
.control-dots .dot {
    background: #a3a3a3 !important;
    margin: -8px 8px !important;
  }
  .fairs-carousel .control-dots  .dot.selected {
    background: var(--fixed-primary) !important;
    
  }
  .fairs-carousel.carousel-status {
    color: var(--fixed-text-dark) !important;
    text-shadow: none !important;
    font-weight: 600
    ;
  }

  .fairs-carousel.control-arrow, .carousel.carousel-slider .control-arrow{
    padding: 10px !important;
  }
  .fairs-carousel.control-arrow, .carousel.carousel-slider .control-arrow::before{
    scale: 1.2;
  }
:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}

.req-container {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.req-container .h2 {
  color: white;
}

.req-container .p {
  color: white;
}

.req-container .img-col {
  padding: 0px;
}

.req-container .img-col img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.req-container .form-col {
  padding: 30px 50px;
}

@media only screen and (max-width:991px){
  .req-container .form-col{
    padding: 20px 10px;
  }
  
}

.req-container .form-col .country-select-box {
  color: #334b35;
  color: var(--fixed-color-dark);
}
.col {
  text-align: left;
}

.req-form {
  text-align: center;
}

.form-group {
  text-align: left;
}
.form-label{
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0;
    color: #31512a;
}

#submit-button {
  background: #5b8c51;
  border: medium none;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 1;
  margin-bottom: 0;
  padding: 20px 40px;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  transition: 0.3s;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid;
  letter-spacing: 0;
}
#submit-button:hover {
  background-color: white !important;
  color: #334b35 !important;
  color: var(--fixed-color-dark) !important;
  
}

.icon-box h3 {
  color: #334b35;
  color: var(--fixed-color-dark);
  font-size: 25px;
  font-weight: 800;
  margin: 5px 0 10px 0;
}
.icon-box i {
  font-size: 48px;
  float: left;
  color: #ffc451;
}
.icon-box p {
  font-size: 15px;
  color: #474747;
}

@media (max-width: 575px) {
  .req_col{
    display: inline;
    padding: 20px;
  }
}



.img-container {
  width: 100%;
  height: 100vh;
  height: 100%;
  min-height: 300px;
}

.img-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.view-count-wrapper{
    margin-left: auto !important;
}
.view-count-wrapper p{
    margin-left: 1rem;
    margin-bottom: 0 !important;
}
:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}

.inside-row {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

}

.main-row {
  display: block !important;
  width: auto;
  margin-bottom: 50px;

}

.jumbo-main {
  padding-top: 4rem;
  padding-right: 5rem;
  padding-left: 5rem;
  position: relative;
  width: 100%;
  height: 850px;
  overflow-x: hidden;
}

.jumbo-main .inside-row .text-box {


  word-break: normal;
  text-align: justify ;
  
}

.jumbo-main .inside-row .text-box .text-box-wrapper {
  position: absolute;
  top: 5rem;
  left: 0;
  width: 120%;
  height: 700px;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-right: 100px;
  padding-left: 4rem;
  background-color: #6d8c54;
  background-color: var(--fixed-primary);
  border-radius: 30px;

}



.jumbo-main .inside-row h1 {
  font-size: 3rem;
  font-weight: 750;
  margin-bottom: 1rem;
 
}

.jumbo-main .inside-row .text-box p {
  line-height: 30px;
  font-size: 10pt;
  font-weight: 400;
  margin-top: 2rem;
  font-weight: 600;
  color: rgb(14, 14, 14);
  
}

.jumbo-main .img-box {
  -webkit-align-content: center;
          align-content: center;
  position: relative;
  max-height: 500px;
  width: 100%;
  height: 500px;
}

.jumbo-main .inside-row .background-div{
  position: absolute;
  width: 80%;
  height: 700px;
  z-index: -1;
  top: 2rem;
  right: -100px;
  background-color: #f1cf69;
  background-color: var(--fixed-secondary);
  border-radius: 30px;
}

.jumbo-main .img-box img {
  object-fit: cover;
  object-position: top left;
  position: absolute;
  top: 0;
  left: 100px;
  width: 80%;
  height: 600px;
  border-radius: 30px;
}



@media only screen and (max-width : 1200px) {
  .jumbo-main {
    padding-top: 4rem;
    padding-right: 1rem;
    padding-left: 1rem;
    position: relative;
    width: 100%;
    height: auto;
    overflow-x: hidden;
  }
  .jumbo-main .inside-row .text-box {
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-right: 2rem;
    padding-left: 2rem;
    word-break: normal;
    text-align: left;
  }
  .jumbo-main .inside-row .text-box .text-box-wrapper {
 
    top: 5rem;
    left: 0;
    width: 120%;
    height: 650px;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-right: 3rem;
    padding-left: 4rem;
    background-color: #6d8c54;
    background-color: var(--fixed-primary);
   border-radius: 30px;
  }  
  .jumbo-main .img-box{
    max-height: 500px;
  }
  .jumbo-main .img-box img {
    object-fit: cover;
    object-position: top left;
    width: 100%;
    left: 0;
    height: 500px;
    aspect-ratio: 1 1;
    border-radius: 30px;
  }
}




/* .jumbo-main .img-box img::after {
  content: "";
  position: absolute;
  right: 0;
  z-index: 150;
  top: 0;
  height: 10px;
  width: 50%; 
  background: var(--fixed-color-dark);
}

.jumbo-main .img-box::before {
  content: "";
  position: absolute;
  left: 10;
  z-index: 100;
  top: 0;
  height: 50%;
  width: 10px;
  background: var(--fixed-primary);
}
.jumbo-main .img-box::after {
  content: "";
  position: absolute;
  right: 0;
  z-index: 100;
  bottom: 0;
  height: 10px;
  width: 50%; 
  background: var(--fixed-primary);
} */

.modal .modify-modal-main{
    padding-inline: 3rem !important;
   
    margin:auto !important;
    max-width: 1200px;
}
.modal .modify-modal-main .modify-modal-main-container{
    padding: 1rem !important;
    
}
:root{
    --disabled-color: rgb(143, 143, 143);
    --disabled-back: rgb(206, 206, 206);
}


.panel-wrapper{
    padding: 20px 15px;
}

.panel-wrapper.disabled{
    pointer-events: none;
    color: grey;
}

.panel{
    position: relative;
    padding: 30px 15px;
    width: 100%;
    border-radius: 10px;
    -webkit-flex: 1 1;
            flex: 1 1;   
    background-color: rgb(248, 248, 248);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.panel-wrapper.disabled .panel{
    background-color: rgb(206, 206, 206);
}
/* .panel::before{
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 2px;
    background-color: var(--fixed-primary-dark);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    opacity: 0.3;
} */

.panel-title-row {
    width: 100%;
}

.panel-wrapper .panel-title-col, .panel-wrapper .panel-title-col>*{
    text-align: left;
    color: var(--fixed-primary);
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
}

.panel-wrapper.disabled .panel-title-col, .panel-wrapper.disabled .panel-title-col>*{
    text-align: left;
    color: rgb(143, 143, 143);
    color: var(--disabled-color);
}

.icon {
    scale: 1.5;
    cursor: pointer;
    -webkit-align-items: center;
            align-items: center;
}

.icon:hover {
    cursor: pointer;
    color: var(--fixed-secondary-dark);
}

.angle-up{
    transition: 0.6s;
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
}
.angle-down{
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
    transition: 0.6s;
}

#main-container > .row > {
  display: -webkit-flex;
  display: flex;
}
#main-container > .row > #leftbar {
  background-color: white;
  height: 50rem;
  -webkit-flex-grow: 0.1;
          flex-grow: 0.1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  border-right: brown solid;
  padding-right: 0rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#main-container > .row > #leftbar>.btn-group{
 width: 10rem;
}

.news-form {
  text-align: center;
}


#admin-submit-button {
  background: #5b8c51;
  border: border solid 1px ;
  border-color: var(--fixed-primary);
  color: #ffffff;
  cursor: pointer;
  border-radius: 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 1;
  margin-bottom: 0;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  transition: 0.3s;
  vertical-align: middle;
  white-space: nowrap;
  padding: 10px 20px;
  letter-spacing: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#admin-submit-button:hover {
  border: 1px solid var(--fixed-color-dark);
  background-color: white !important;
  color: var(--fixed-color-dark) !important;
  
}
.tag-form{
    -webkit-flex: 1 1;
            flex: 1 1;
    position: relative;
    left: 5px;
    -webkit-justify-content: center;
            justify-content: center;
}
.tag-form .tag-input{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.tags{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.tag-form .admin-submit-btn{
    border-radius: 0;
    background-color: var(--fixed-primary);
    color: white;
    outline: 0;
}
#modal-main-container.container{
padding: 1rem !important;
}

.col-xl-12 .toolbar-button {
  font-size: 12pt;
  border-color: white;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border-radius: 10px;
}

.col-xl-12 .toolbar-button:hover {
  border-color: var(--fixed-primary);
  background-color: rgba(255, 255, 255, 1);
  color: var(--fixed-primary);
  border-radius: 15px;
}

.col-12 .modal-button {
  border-radius: 10px;
}

.col-12 .modal-delete-btn {
  border-color: white;
  background-color: rgb(179, 37, 37);
  color: white;
}

.col-12 .modal-delete-btn:hover {
  border-color: rgb(179, 37, 37);
  background-color: white;
  color: rgb(179, 37, 37);
}

.col-12 .modal-answer-btn {
  border-color: white;
  background-color: var(--fixed-primary);
  color: white;
}

.col-12 .modal-answer-btn:hover {
  border-color: var(--fixed-primary);
  background-color: white;
  color: var(--fixed-primary);
}

.custom-file-inputfield{
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.custom-file-inputfield-label{
    width: 300px;
    cursor: pointer;
    font-family: "Poppins";
    padding: 10px 30px;
 font-size: 1em;
    font-weight: 700;
    color: white;
    background-color: var(--fixed-secondary);
    transition: 0.3s ease;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.custom-file-inputfield-label:hover{
    -webkit-transform: translate(0, -6px);
            transform: translate(0, -6px);
}

.modal .modify-modal-main{
    padding-right: 3rem !important;
    padding-left: 3rem !important;
    margin:auto !important;
    max-width: 1200px;
}
.modal .modify-modal-main .modify-modal-main-container{
    padding: 3rem !important;
    
}
.mail-creator-wrapper{
    margin-block: 2.5rem;

}

.image-wrapper{
    margin: 1rem;
    max-width: 100%;
    max-height: 100%;
    background-color: rgb(231, 231, 231);
}

.image-wrapper img{
    width: 100%;
    aspect-ratio: 4 / 3;
    
    object-fit: cover;
}
.modal .modify-modal-main{
    padding-right: 3rem !important;
    padding-left: 3rem !important;
    margin:auto !important;
    max-width: 1200px;
}
.modal .modify-modal-main .modify-modal-main-container{
    padding: 1rem !important;
    
}
:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}


@media screen and (min-width: 991px) {

  #Title{
    font-size: 24pt !important;
  }

  .subtitle {
    font-size: 18pt !important;
  }
}
@media screen and (max-width: 990px) {

  #Title{
    font-size: 20pt !important;
  }

  .subtitle {
    font-size: 14pt !important;
  }
}


@media screen and (min-width: 1140px) {
  .main-body {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .main-body .author-col {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    padding: auto;
  }

  .main-body .related-news-col {
    padding-right: 20px;
    padding-left: 40px;
    border-left: solid 2px;
    border-color: #334b35;
    border-color: var(--fixed-color-dark);
  }
}

#Body {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  text-align: justify;
  padding-left: 5%;
  padding-right: 5%;
}
#top-image-row {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 5%;
}

#Body #ReferencesId {
  /*margin-left: 20rem;*/
  margin-top: 5%;
}

#Title {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-bottom: 50px;
  font-weight: bold;
}

#tags {
  -webkit-justify-content: center;
          justify-content: center;

  -webkit-align-items: center;

          align-items: center;
  margin-right: 10%;
  margin-left: 10%;
}

#Author {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 30%;
  margin-right: 10%;
  margin-left: 10%;
  background-color: #f1cf69;
  background-color: var(--fixed-secondary);
}

#middle-row {
  margin-top: 50px;
}

#Popular {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

#Popular > #newsrow > #Title {
  padding: 1rem;
}
#Popular > #Cartsrow {
  margin-top: 2rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}
#Carts > .card-img-top {
  height: 17rem;
  width: 100%;
}
#Carts > .card-body {
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

#Carts {
  margin-left: 1.9rem;
  margin-right: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: -8px 8px 6px -6px #999;
  width: 20rem;
}

:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}
.row {
  margin-right: 0px;
  margin-left: 0px;
}

.author-main-row {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #f1cf69;
  background-color: var(--fixed-secondary);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.author-main {
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 40px 20px;
  margin-right: auto;
  margin-left: auto;
}

.author-main .img-box {
  -webkit-align-content: center;
          align-content: center;
  position: relative;
}

.img-box .author_name {
  text-align: center;
}
.img-box .author_title {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .author-main-row {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .author-main-row .img-box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .author-main-row .title-col {
    margin: auto;
  }
  .author-main-row .title-col .title-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
  .author-main-row .img-box .image {
    width: 100px;
    object-fit: cover;
    position: relative;
    height: 100px;
    border-radius: 50%;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .author-main-row .img-box .image {
    width: 160px;
    object-fit: cover;
    position: relative;
    height: 160px;
    border-radius: 50%;
  }

  .author-main-row {
    padding: 1rem;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .author-main-row .img-box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
  
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .author-main-row .title-col {
    margin: auto;
  }
  .author-main-row .title-col .title-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
}

@media screen and (min-width: 991px) {
  .author-main-row {
    padding: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    background-color: #f1cf69;
    background-color: var(--fixed-secondary);
  }

  .author-main-row .img-box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .author-main-row .title-col {
    margin: auto;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 20px;
  }
  .author-main-row .title-col .title-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
  .author-main-row .title-col .title-row .author_name{
    font-size: 18pt;
    font-weight: 600;
    color: var(--fixed-text-dark);
  }
  .author-main-row .title-col .title-row .editor{
    font-size: 12pt;
    font-weight: 600;
    color: var(--fixed-text-dark);
  }

  .img-box .image {
    width: 256px;
    object-fit: cover;
    position: relative;
    height: 256px;
    border-radius: 50%;
  }
}

#CardDeck {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;

  border: #999;
}

.CardsRow > #CardDeck > #newscard {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: -8px 8px 6px -6px #999;
  width: 20rem;
  text-align: justify;
  color: black;
}

.CardImages {
  width: 400px;
  height: 300px;
}

.search-res-container .search-title h3{
    font-size: 2.5rem ;
    margin-top: 3rem;

}
.site-footer
{
  background-color:#F0CF68;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#334A35;
  margin-top: 45px;
}
.site-footer .about-col
{
  padding-right: 45px;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:#fff;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#334A35
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#fff;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
/* This pen */
 .sup_body {
	 font-family: "Baloo 2", cursive;
	 font-size: 16px;
	 color: #fff;
	 text-rendering: optimizeLegibility;
	 font-weight: normal;
	 font-weight: initial;
}
 .dark {
	 background: #110f16;
}
 .light {
	 background: #f3f5f7;
}

.subs-button, .subs-button:hover {
	font-size: 12pt;
	font-weight: 600;
	text-decoration: none;
	transition: color 0.3s ease-in-out;

}

.subs-button {
	color: rgb(226, 226, 226);

}
.subs-button:hover {
	color:rgb(255, 255, 255);

}
a, a:hover {
	 text-decoration: none;
	 transition: color 0.3s ease-in-out;
}
 #pageHeaderTitle {
	 margin: 2rem 0;
	 text-transform: uppercase;
	 text-align: center;
	 font-size: 2.5rem;
}
/* Cards */
 .postcard {
	 -webkit-flex-wrap: wrap;
	         flex-wrap: wrap;
	 display: -webkit-flex;
	 display: flex;
	 box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
	 border-radius: 10px;
	 margin: 0 0 2rem 0;
	 overflow: hidden;
	 position: relative;
	 color: #fff;
}
 .postcard.dark {
	 background-color: #18151f;
}
 .postcard.light {
	 background-color: #e1e5ea;
}
 .postcard .t-dark {
	 color: #18151f;
}

 .postcard h1, .postcard .h1 {
	 margin-bottom: 0.5rem;
	 font-weight: 500;
	 line-height: 1.2;
}
 .postcard .small {
	 font-size: 80%;
}
 .postcard .postcard__title {
	 font-size: 1.75rem;
}
 .postcard .postcard__img {
	 max-height: 180px;
	 width: 100%;
	 object-fit: cover;
	 position: relative;
}
 .postcard .postcard__img_link {
	 display: contents;
}
 .postcard .postcard__bar {
	 width: 50px;
	 height: 10px;
	 margin: 10px 0;
	 border-radius: 5px;
	 background-color: #424242;
	 transition: width 0.2s ease;
}
 .postcard .postcard__text {
	 padding: 1.5rem;
	 position: relative;
	 display: -webkit-flex;
	 display: flex;
	 -webkit-flex-direction: column;
	         flex-direction: column;
}
 .postcard .postcard__preview-txt {
	 overflow: hidden;
	 text-overflow: ellipsis;
	 text-align: justify;
	 height: 100%;
}
 .postcard .postcard__tagbox {
	 display: -webkit-flex;
	 display: flex;
	 -webkit-flex-flow: row wrap;
	         flex-flow: row wrap;
	 font-size: 14px;
	 margin: 20px 0 0 0;
	 padding: 0;
	 -webkit-justify-content: center;
	         justify-content: center;
}
 .postcard .postcard__tagbox .tag__item {
	height: 40px;
	 display: -webkit-flex;
	 display: flex;
	 -webkit-align-items: center;
	         align-items: center;
	 -webkit-justify-content: center;
	         justify-content: center;
	 text-align: center;
	 background: rgba(83, 83, 83, 0.4);
	 border-radius: 3px;
	 padding: 2.5px 10px;
	 margin: 0 5px 5px 0;
	 cursor: default;
	 -webkit-user-select: none;
	     -ms-user-select: none;
	         user-select: none;
	 transition: background-color 0.3s;
}
 .postcard .postcard__tagbox .tag__item:hover {
	 background: rgba(83, 83, 83, 0.8);
}
 .postcard:before {
	 content: "";
	 position: absolute;
	 top: 0;
	 right: 0;
	 bottom: 0;
	 left: 0;
	 background-image: linear-gradient(-70deg, #424242, transparent 50%);
	 opacity: 1;
	 border-radius: 10px;
}
 .postcard:hover .postcard__bar {
	 width: 100px;
}
 @media screen and (min-width: 769px) {
	 .postcard {
		 -webkit-flex-wrap: inherit;
		         flex-wrap: inherit;
	}
	 .postcard .postcard__title {
		 font-size: 2rem;
	}
	 .postcard .postcard__tagbox {
		 -webkit-justify-content: start;
		         justify-content: start;
	}
	 .postcard .postcard__img {
		 max-width: 300px;
		 max-height: 100%;
		 transition: -webkit-transform 0.3s ease;
		 transition: transform 0.3s ease;
		 transition: transform 0.3s ease, -webkit-transform 0.3s ease;
	}
	 .postcard .postcard__text {
		 padding: 3rem;
		 width: 100%;
	}
	 .postcard .media.postcard__text:before {
		 content: "";
		 position: absolute;
		 display: block;
		 background: #18151f;
		 top: -20%;
		 height: 130%;
		 width: 55px;
	}
	 .postcard:hover .postcard__img {
		 -webkit-transform: scale(1.1);
		         transform: scale(1.1);
	}
	 .postcard:nth-child(2n+1) {
		 -webkit-flex-direction: row;
		         flex-direction: row;
	}
	 .postcard:nth-child(2n+0) {
		 -webkit-flex-direction: row-reverse;
		         flex-direction: row-reverse;
	}
	 .postcard:nth-child(2n+1) .postcard__text::before {
		 left: -12px !important;
		 -webkit-transform: rotate(4deg);
		         transform: rotate(4deg);
	}
	 .postcard:nth-child(2n+0) .postcard__text::before {
		 right: -12px !important;
		 -webkit-transform: rotate(-4deg);
		         transform: rotate(-4deg);
	}
}
 @media screen and (min-width: 1024px) {
	 .postcard__text {
		 padding: 2rem 3.5rem;
	}
	 .postcard__text:before {
		 content: "";
		 position: absolute;
		 display: block;
		 top: -20%;
		 height: 130%;
		 width: 55px;
	}
	 .postcard.dark .postcard__text:before {
		 background: #18151f;
	}
	 .postcard.light .postcard__text:before {
		 background: #e1e5ea;
	}
}
/* COLORS */
 .postcard .postcard__tagbox .green.play:hover {
	 background: #79dd09;
	 color: black;
}
 .green .postcard__title:hover {
	 color: #79dd09;
}
 .green .postcard__bar {
	 background-color: #79dd09;
}
 .green::before {
	 background-image: linear-gradient(-30deg, rgba(121, 221, 9, 0.1), transparent 50%);
}
 .green:nth-child(2n)::before {
	 background-image: linear-gradient(30deg, rgba(121, 221, 9, 0.1), transparent 50%);
}

.postcard .postcard__tagbox .blue.play {
	background: #1d90d8;
}

 .postcard .postcard__tagbox .blue.play:hover {
	 background: #0076bd;
}
 .blue .postcard__title:hover {
	 color: #0076bd;
}
 .blue .postcard__bar {
	 background-color: #0076bd;
}
 .blue::before {
	 background-image: linear-gradient(-30deg, rgba(0, 118, 189, 0.1), transparent 50%);
}
 .blue:nth-child(2n)::before {
	 background-image: linear-gradient(30deg, rgba(0, 118, 189, 0.1), transparent 50%);
}
.postcard .postcard__tagbox .red.play {
	background: #cc281f;
}
 .postcard .postcard__tagbox .red.play:hover {
	 background: #bd150b;
}
 .red .postcard__title:hover {
	 color: #bd150b;
}
 .red .postcard__bar {
	 background-color: #bd150b;
}
 .red::before {
	 background-image: linear-gradient(-30deg, rgba(189, 21, 11, 0.1), transparent 50%);
}
 .red:nth-child(2n)::before {
	 background-image: linear-gradient(30deg, rgba(189, 21, 11, 0.1), transparent 50%);
}
 .postcard .postcard__tagbox .yellow.play:hover {
	 background: #bdbb49;
	 color: black;
}
 .yellow .postcard__title:hover {
	 color: #bdbb49;
}
 .yellow .postcard__bar {
	 background-color: #bdbb49;
}
 .yellow::before {
	 background-image: linear-gradient(-30deg, rgba(189, 187, 73, 0.1), transparent 50%);
}
 .yellow:nth-child(2n)::before {
	 background-image: linear-gradient(30deg, rgba(189, 187, 73, 0.1), transparent 50%);
}
 @media screen and (min-width: 769px) {
	 .green::before {
		 background-image: linear-gradient(-80deg, rgba(121, 221, 9, 0.1), transparent 50%);
	}
	 .green:nth-child(2n)::before {
		 background-image: linear-gradient(80deg, rgba(121, 221, 9, 0.1), transparent 50%);
	}
	 .blue::before {
		 background-image: linear-gradient(-80deg, rgba(0, 118, 189, 0.1), transparent 50%);
	}
	 .blue:nth-child(2n)::before {
		 background-image: linear-gradient(80deg, rgba(0, 118, 189, 0.1), transparent 50%);
	}
	 .red::before {
		 background-image: linear-gradient(-80deg, rgba(189, 21, 11, 0.1), transparent 50%);
	}
	 .red:nth-child(2n)::before {
		 background-image: linear-gradient(80deg, rgba(189, 21, 11, 0.1), transparent 50%);
	}
	 .yellow::before {
		 background-image: linear-gradient(-80deg, rgba(189, 187, 73, 0.1), transparent 50%);
	}
	 .yellow:nth-child(2n)::before {
		 background-image: linear-gradient(80deg, rgba(189, 187, 73, 0.1), transparent 50%);
	}
}



 
:root {
    --fixed-primary: #6d8c54;
    --fixed-secondary: #f1cf69;
    --fixed-secondary-dark: #e2b93e;
    --fixed-color-dark: #334b35;
    --fixed-primary-btn: #5b8c51;
    --fixed-text-white:  #f5f5f5;
    --fixed-text-white-sec:  #dadada;
    --fixed-text-dark:  #1b1b1b;
  }
h1{
  font-size: xxx-large !important;
}

label{
  font-family: "Poppins";
}

input[type=checkbox] {
  accent-color: #6d8c54;
  accent-color: var(--fixed-primary);
}
.btn-wrapper #close-btn {
    background-color: red;
    border-color: red;
    padding: 5px 10px; 
}
.btn-wrapper #close-btn:hover{
    background-color: rgb(153, 0, 0);
    border-color: rgb(153, 0, 0);
}
.btn-wrapper #close-btn:focus{
    box-shadow: none;
    outline: none;
    background-color: rgb(153, 0, 0);
    border-color: rgb(153, 0, 0);
}
.btn-wrapper #close-btn:active {
    box-shadow: none;
    outline: none;
    background-color: rgb(153, 0, 0);
    border-color: rgb(153, 0, 0);
    box-shadow: none;
    outline: none;
}
:root {
  --fixed-color-dark: #334b35;
  --fixed-secondary: #f1cf69;
}

.main-services .icon-box {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}
.main-services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #334b35;
  background: var(--fixed-color-dark);
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.main-services .icon-box .image {
  margin: 0 auto;
  width: 256px;
  height: 256px;
  background: #334b35;
  background: var(--fixed-color-dark);
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}
.main-services .icon-box .icon i {
  color: #151515;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}
.main-services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #151515;
  transition: ease-in-out 0.3s;
}

.main-services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.main-services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  background-color: #f1cf69;
  background-color: var(--fixed-secondary);
}

.main-services .icon-box:hover h4 {
  color: #fff;
}

:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}

.navbar-container.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgb(255, 255, 255);
}

.navbar-container {
  position: relative;
  z-index: 100;
}

.main-nav-row .logo-col .image.small {
  height: 75px;
  max-height: 100%;
  object-fit: cover;
  transition: 0.6s;
}
.main-nav-row .logo-col .image {
  height: 120px;
  max-height: 100%;
  object-fit: cover;
  transition: 0.6s;
}

@media screen and (min-width: 1336px) {
  .main-nav-row {
    position: relative;
    padding-left: 50px;
    padding-right: 25px;
    border-bottom: solid 10px;
    border-color: #6d8c54;
    border-color: var(--fixed-primary);
  }

  .main-nav-row .logo-col {
    text-align: center;
    -webkit-flex: 16.666% 1;
            flex: 16.666% 1;
  }
  .main-nav-row .search-col {
    text-align: center;
    -webkit-flex: 16.666% 1;
            flex: 16.666% 1;
  }
  .main-nav-row .search-col .link-row .col{
    text-align: center;
    -webkit-flex: 50% 1;
            flex: 50% 1;
  }

  
  .main-nav-row .link-row {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    height: 100%;
    width: 100%;
  }

  .main-nav-row .link-col-container {
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: auto;
    margin-right: 10px;
    margin-left: 10px;
  }
  .main-nav-row .link-col-container h4 {
    font-weight: 750;
  }

  .main-nav-row .link-col-container .search-col{
    margin-left: 2rem;
  }

  .main-nav-row .hamburger {
    position: relative;
    margin: auto;
    display: none;
  }
}


@media screen and (min-width: 1336px) {
  .main-nav-row .link-col-container {
    -webkit-flex: 58.3333% 1;
            flex: 58.3333% 1;
  }
}


@media screen and (max-width: 1336px) {
  .main-nav-row {
    padding: 10px;
    margin-top: 5px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .main-nav-row .logo-col {
    text-align: center;
    width: 200px;
  }

  .main-nav-row .link-row {
    -webkit-justify-content: center;
            justify-content: center;
  }
  

  .main-nav-row .link-col-container {
    -webkit-flex: 100% 1;
            flex: 100% 1;
    height: 100%;
    text-align: center;
    transition: 1s;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .main-nav-row .link-col-container.open {
    display: -webkit-flex;
    display: flex;
    position: relative;
    height: 250px;
    width: 100%;
  }
  .main-nav-row .link-col-container.closed {
    height: 0px;
  }

  .main-nav-row .link-col-container.closed * {
    display: none;
  }

  .main-nav-row .hamburger {
    position: relative;
    display: inline;
    color: #6d8c54;
    color: var(--fixed-primary);
    background-color: white;
    border: 0px;
  }

  .main-nav-row .link-col-container .link-col{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex: 75% 1;
            flex: 75% 1;
  }
  .main-nav-row .link-col-container .search-col{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 75% 1;
            flex: 75% 1;
  }
  .main-nav-row .search-col .link-row .col{
    text-align: center;
    -webkit-flex: 75% 1;
            flex: 75% 1;
  }
}

.main-nav-row .search-col {
  text-align: center;
  padding: 0px;
  -webkit-justify-content: center;
          justify-content: center;
}
.main-nav-row .request-col {
  text-align: center;
  padding: 0px;
  -webkit-justify-content: center;
          justify-content: center;
  margin: auto;
}


.main-nav-row a {
  text-decoration: none;
  color: #6d8c54;
  color: var(--fixed-primary);
}

.main-nav-row a:not([href]) {
  text-decoration: none;
  color: #6d8c54;
  color: var(--fixed-primary);
}

.main-nav-row a:not([href]):hover {
  text-decoration: none;
  color: #6d8c54;
  color: var(--fixed-primary);
}

.main-nav-row .link-col-container a::after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f1cf69;
  background-color: var(--fixed-secondary);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.75s ease-out;
  transition: transform 0.75s ease-out;
  transition: transform 0.75s ease-out, -webkit-transform 0.75s ease-out;
}
.main-nav-row .link-col-container a:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

/* Dropdown Button */
.dropbtn {
  background-color: white;
  color: #6d8c54;
  color: var(--fixed-primary);

  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

.main-nav-row .link-col-container .dropdown {
  color: #6d8c54 !important;
  color: var(--fixed-primary) !important;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: absolute;
  left: 50%;
  top: 100%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 200px;
  background-color: rgb(255, 255, 255);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 103;
  transition: ease-in 0.6s;
  font-weight: 450;
  text-align: center;
}



/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  display: block;
  transition: 0.6s;
}

/*Dropdown item animation*/
.dropdown .dropdown-content a{
  padding: 0;
  height: 0px;
  transition: ease-in 0.6s;
}
.dropdown:hover .dropdown-content a{
  height: 50px;
  transition: ease-out 0.6s;
}

.dropdown .dropdown-content p {
  display: none;
  margin: 0;
  opacity: 0;
  transition: ease-out 0.4s;
}

.dropdown:hover .dropdown-content p {
  display: block;
  margin: 0;
  opacity: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  position: relative;
  z-index: 102;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: rgb(255, 255, 255);
  color: #6d8c54;
  color: var(--fixed-primary);
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.dropbtn::after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f1cf69;
  background-color: var(--fixed-secondary);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.75s ease-out;
  transition: transform 0.75s ease-out;
  transition: transform 0.75s ease-out, -webkit-transform 0.75s ease-out;
}

.main-nav-row .link-col {
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: center;
}

.rdt_Table {

    height: 600px !important;
}
.rdt_TableBody {

    overflow-y: scroll;
    cursor: pointer;
}

.category-select{
    width: 100%;
    color: var(--fixed-text-dark);
}
.all-products-main-container{
    background-image: url("https://res.cloudinary.com/da8slr0ic/image/upload/v1687631861/foodsfromturkey/haxgohmrlti44ylmzcw2.jpg");
    background-size: cover;
    position: relative;
    z-index: 0;
    padding-inline: 2rem !important;
}

.all-products-main-container::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--fixed-text-dark);
    opacity: 0.9;
    z-index: 1;
}

.all-products-main-container .prod-table-wrapper{
    position: relative;
    z-index: 2;
}

.all-products-main-container .prod-table-wrapper h1{
    font-size: 24pt;
    font-weight: bold;
    color: var(--fixed-secondary);
    
}

.all-products-main-container .prod-table-wrapper p{
    margin-top: 1rem;
    font-size: 14pt;
    color: var(--fixed-text-white-sec);
    
}


.about-us-main-container{
    height: 100%;
    background-color: var(--fixed-secondary);
    
}

/* Layout*/
.about-us-main-container .row {
    width: 100%;
    padding-inline: 4rem ;
    -webkit-justify-content: center;
            justify-content: center;
    padding-block: 5rem;
}



.about-us-main-container .aboutus-cards-row {
    background-color: var(--fixed-primary);
}


.about-us-main-container .row-r{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
} 

.about-us-main-container .row-l{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
} 


.about-us-main-container .row .num-card{
    color: var(--fixed-primary);
    font-weight: bold;
}


/* Image part*/

.about-us-main-container .row .img-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 100%;
    min-height: 500px;
    min-width: 400px;
    text-align: center;
}

.about-us-main-container .row .img-wrapper img{
    width: 90%;
    height: 90%;
    object-fit: cover;
    position: relative;
    z-index: 3;
}

.about-us-main-container .row-r .img-wrapper::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 90%;
    height: 90%;
    background-color: var(--fixed-primary);
   
    z-index: 0;
}

.about-us-main-container .row-l .img-wrapper::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    width: 90%;
    height: 90%;
    background-color: var(--fixed-primary);

    z-index: 0;
}

/* Texts*/
.about-us-main-container .row h1{
    font-weight: bold;
    color: var(--fixed-text-dark);
    font-size: 32pt;
    margin-block: 1.5rem;
}
.about-us-main-container .row h2{
    font-weight: bold;
    color: var(--fixed-text-dark);
    font-size: 32pt;
    margin-block: 1.5rem;
}
.about-us-main-container .row h3{
    font-weight: 500;
    color: var(--fixed-text-dark);
    font-size: 24pt;
    margin-block: 1rem;
}
.about-us-main-container .row p{
    font-weight: 500;
    color: var(--fixed-text-dark);
    font-size: 14pt;
    text-align: justify;
    margin-block: 3rem;
}


/*Media*/


@media only screen and (max-width: 992px) {
    .about-us-main-container .row {
        padding-inline: 0.5rem ;
    }

    .about-us-main-container .row-r{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
    } 
    
    .about-us-main-container .row-l{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    } 

    .about-us-main-container .row .img-wrapper::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background-color: var(--fixed-primary);
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
        z-index: 0;
    }
}

.num-disp-wrapper{
    margin: 2rem;
    box-shadow: 2px;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
}

.num-disp-wrapper .num-disp-card{
    width: 200px;
    text-align: center;
    color: var(--fixed-primary);
    background-color: var(--fixed-secondary);;
    font-weight: bold;
    transition: 0.6s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 1rem;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    
}
.num-disp-wrapper .num-disp-card .label{
    color: inherit;
    text-align: center;
    font-weight: bold;
    font-size: 18pt;
    margin: 0px;

}
.num-disp-wrapper .num-disp-card .num{
    font-weight: bold;
    text-align: center;
    font-size: 14pt;
    color: inherit;
    margin: 0px;
}

