.tag-form{
    flex: 1;
    position: relative;
    left: 5px;
    justify-content: center;
}
.tag-form .tag-input{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.tags{
    display: flex;
    justify-content: center;
}

.tag-form .admin-submit-btn{
    border-radius: 0;
    background-color: var(--fixed-primary);
    color: white;
    outline: 0;
}