.mail-creator-wrapper{
    margin-block: 2.5rem;

}

.image-wrapper{
    margin: 1rem;
    max-width: 100%;
    max-height: 100%;
    background-color: rgb(231, 231, 231);
}

.image-wrapper img{
    width: 100%;
    aspect-ratio: 4 / 3;
    
    object-fit: cover;
}