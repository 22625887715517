:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}

.req-container {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.req-container .h2 {
  color: white;
}

.req-container .p {
  color: white;
}

.req-container .img-col {
  padding: 0px;
}

.req-container .img-col img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.req-container .form-col {
  padding: 30px 50px;
}

@media only screen and (max-width:991px){
  .req-container .form-col{
    padding: 20px 10px;
  }
  
}

.req-container .form-col .country-select-box {
  color: var(--fixed-color-dark);
}
.col {
  text-align: left;
}

.req-form {
  text-align: center;
}

.form-group {
  text-align: left;
}
.form-label{
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0;
    color: #31512a;
}

#submit-button {
  background: #5b8c51;
  border: medium none;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 1;
  margin-bottom: 0;
  padding: 20px 40px;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  transition: 0.3s;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid;
  letter-spacing: 0;
}
#submit-button:hover {
  background-color: white !important;
  color: var(--fixed-color-dark) !important;
  
}

.icon-box h3 {
  color: var(--fixed-color-dark);
  font-size: 25px;
  font-weight: 800;
  margin: 5px 0 10px 0;
}
.icon-box i {
  font-size: 48px;
  float: left;
  color: #ffc451;
}
.icon-box p {
  font-size: 15px;
  color: #474747;
}

@media (max-width: 575px) {
  .req_col{
    display: inline;
    padding: 20px;
  }
}


