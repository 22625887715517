:root {
    --fixed-primary: #6d8c54;
    --fixed-secondary: #f1cf69;
    --fixed-secondary-dark: #e2b93e;
    --fixed-color-dark: #334b35;
    --fixed-primary-btn: #5b8c51;
    --fixed-text-white:  #f5f5f5;
    --fixed-text-white-sec:  #dadada;
    --fixed-text-dark:  #1b1b1b;
  }
h1{
  font-size: xxx-large !important;
}

label{
  font-family: "Poppins";
}

input[type=checkbox] {
  accent-color: var(--fixed-primary);
}