:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
  --search-comp-height-large: 150px;
  --search-comp-height-med: 100px;
  --back-color: rgba(
    241,
    207,
    105,
    0.95
  ); /* Black with a little bit see-through */
  --input-color: rgba(0, 0, 0, 0); /*Transparent*/
  --hover-color-btn: var(--fixed-primary);
  --transition-time: 0.6s;
}

/* #region Main Container */
.searchbar-main-container {
  transition: var(--transition-time);
}

.searchbar-main-container.closed {
  /* display: none; */
  height: 0;
}

.searchbar-main-container.closed * {
  display: none;
}

.searchbar-main-container.open {
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 0;
  background-color: var(--back-color);
}

.searchbar-main-container.open .search-lbl {
  display: flex;
  justify-content: center;
}

.searchbar-main-container.open .search-form {
  position: relative;
  margin: auto;
}

/* #endregion */

/* #region Close Button */

.searchbar-main-container.open .close-btn {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  background-color: var(--input-color);
  border: 0px;
  margin-top: 10px;
  margin-right: 10px;
  transition: var(--transition-time);
}

.searchbar-main-container.open .close-btn:hover {
  background-color: var(--hover-color-btn);
}

/* #endregion */

/* Required for aligning text-box and button*/
.searchbar-main-container.open .input-box {
  display: flex;
}

.input-box{
  display: flex;
  justify-content: center;
}

/*text-box */
.input-box .search-input {
  width: 100%;
  height: 150px;
  background-color: var(--input-color);
  font-size: 32pt;
  font-family: "Poppins";
  color: white;
  border: 0px;
  border-bottom: 3px solid;
  border-color: rgb(255, 255, 255);
  text-align: center;
  transition: var(--transition-time);
}

@media only screen and (max-width: 1180px) {
  .input-box .search-input {
    width: 200px;
    height: var(--search-comp-height-med);
  }
  .searchbar-main-container.open .input-box .search-btn {
    width: 80px;
    height: var(--search-comp-height-med);
  }
}

.input-box .search-input:focus {
  outline: none !important;
  border-color: var(--hover-color-btn);
  color: var(--hover-color-btn);
}

.input-box .search-input::placeholder {
  color: white;
  transition: var(--transition-time);
}

.input-box .search-input:focus::placeholder {
  outline: none !important;
  border-color: var(--hover-color-btn);
  color: var(--hover-color-btn);
}


.input-box.hero .search-input:focus {
  outline: none !important;
  border-color: var(--fixed-secondary);
  color: var(--fixed-secondary);
}

.input-box.hero .search-input::placeholder {
  color: white;
  transition: var(--transition-time);
}

.input-box.hero .search-input:focus::placeholder {
  outline: none !important;
  border-color: var(--fixed-secondary);
  color: rgb(212, 212, 212);
}

/*submit button*/
.input-box .search-btn {
  height: var(--search-comp-height-large);
  width: 80px;
  background-color: var(--input-color);
  border: 0px solid;
  border-bottom: 3px solid;
  border-radius: 0px;
  transition: var(--transition-time);
  outline: none !important;
}

.input-box .search-btn:hover {
  width: 120px;
  background-color: var(--input-color);
  border-color: var(--hover-color-btn);
  color: var(--hover-color-btn);
}

.searchbar-main-container.open .input-box .search-btn:focus {
  box-shadow: none;
  border-color: var(--hover-color-btn);
  background-color: var(--input-color);
}

.searchbar-main-container.open .input-box .search-btn:active {
  border-color: var(--hover-color-btn);
  background-color: var(--fixed-color-dark);
}

.input-box.hero .search-btn:hover {
  width: 120px;
  background-color: transparent;
  border-color: var(--fixed-secondary);
  color: var(--fixed-secondary);
}

.searchbar-main-container.open .input-box.hero .search-btn:focus {
  box-shadow: none;
  border-color: var(--fixed-secondary);
  background-color: var(--fixed-secondary);
}

.searchbar-main-container.open .input-box.hero .search-btn:active {
  border-color: var(--fixed-secondary);
  background-color: var(--fixed-secondary);
}

.title {
  font-family: "Poppins";
  color: var(--fixed-primary);
}
