@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
#main-container {
  padding-left: 0rem;
  padding-right: 0rem;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
}

.row {
  margin-right: 0  !important;
  margin-left: 0  !important;
}

.container {
  padding: 0 !important;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family:  "Poppins", sans-serif;
  font-weight: 500;
}
p {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: #777777;
  margin-bottom: 15px;
}
