:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}

#features {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: var(--fixed-secondary);
  position: relative;
  overflow: auto;
}

#features .bg-img {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.1;
}

#features .icon-box {
  padding-left: 15px;
}
#features .icon-box h3 {
  color: var(--fixed-color-dark);
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}
#features .icon-box i {
  font-size: 48px;
  float: left;
  color: #ffc451;
}
#features .icon-box p {
  font-size: 15px;
  color: #474747;
  margin-left: 60px;
}
#features .image {
  background-color: var(--fixed-primary);
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}

#features .image img {
  position: absolute;
  top: -25px;
  left: -25px;
}
