:root {
  --fixed-primary: #6d8c54;
  --fixed-secondary: #f1cf69;
  --fixed-color-dark: #334b35;
}

.news-container {
  width: 100%;
}

.news-container .news-icon-box {
  position: relative;
  justify-content: center;
  text-align: left;
  border: 1px solid #ebebeb;
  height: 300px;
  transition: all ease-in-out 0.3s;
  background: var(--fixed-primary);
}
.news-container .news-icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: var(--fixed-primary);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.news-container .news-icon-box .news-image {
  position: absolute;
  padding: 0px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: var(--fixed-secondary);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.news-container .news-icon-box .news-image > .image-span{
  width: 100%;
  height: 100%;
  position: relative;
}

.news-container .news-icon-box .news-image .image-span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1b1a1a;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.6;
}


.news-container .news-icon-box .icon i {
  color: var(--fixed-color-dark);
  font-size: 28px;
  transition: ease-in-out 0.3s;
}
.news-container .news-icon-box .content-col{
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}




.news-container .news-icon-box h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: #ffffff;
  transition: ease-in-out 0.3s;
}

.news-container .news-icon-box:hover h3 {
  color: var(--fixed-secondary);
}
.news-container .news-icon-box p {
  line-height: 24px;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 0;
}
.news-container .news-icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateX(10px);
  border-bottom-right-radius: 20% 50%;
  border-top-right-radius: 20% 50%;
}

.news-container .news-icon-box .arrow-right {
  position: relative;
  z-index: 2;
  transition: ease-in-out 0.3s;
  color: #ffffff;
}

.news-container .news-icon-box:hover .arrow-right {
  color: var(--fixed-secondary);
}


.row.arrow-row {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.arrow-row .arrow-btn{
  background-color: var(--fixed-secondary);
  border:none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.arrow-row .page-count{
  display: flex;
  margin-bottom: 0;
  align-items: center;
  font-weight: bold;
}